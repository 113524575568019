
.swiper-pagination {
  position: absolute;
  bottom: 6px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #ccc;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #007aff;
  width: 12px;
  height: 12px;
}
/* 
.swiper-button-next,
.swiper-button-prev {
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: black;
  box-shadow: 2px 2px 10px #797979;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: var(--font-regular);
  color: var(--color-black);
  font-weight: var(--font-weight-bold);
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
} */
.facility-img-zone{
  width: 100%;
}
.facility-web, .facility-mobile, .facility-mobile2 {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.facility-web .swiper-slide, .facility-mobile .swiper-slide, .facility-mobile2 .swiper-slide {
  display: flex;
  justify-content: center;
}
.custom-swiper-prev,
.custom-swiper-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #333;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8); 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prev-icon, .next-icon{
  width: 30%;
  height: 46%;
}
.custom-swiper-prev {
  left: 0px; /* 왼쪽 끝에 배치 */
}

.custom-swiper-next {
  right: 0px; /* 오른쪽 끝에 배치 */
}

.custom-swiper-prev:active,
.custom-swiper-next:active {
  background-color: rgba(107, 100, 122, 0.8);
  color: #fff;
}
@media all and (width>1023px) {
  .facility-mobile, .facility-mobile2 {
    display: none;
  }
  .img-card {
    width: 330px;
    min-width: 280px;
    height: 380px;
    display: flex;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
    background: linear-gradient(135deg, #3f3b70, #2f2c57);
    flex-direction: column;
    margin-top: 50px;
  }
  .img-zone {
    display: flex;
    object-fit: cover;
    overflow: hidden;
    height: 380px;
  }
  .img-zone img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .facility-web, .facility-mobile2 {
    display: none;
  }
  .facility-mobile {
    display: flex;
    justify-content: center;
  }
  .img-card {
    width: 330px;
    height: 380px;
    display: flex;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
    background: linear-gradient(135deg, #3f3b70, #2f2c57);
    flex-direction: column;
    margin-top: 50px;
  }
  .img-zone {
    display: flex;
    object-fit: cover;
    overflow: hidden;
    height: 380px;
  }
  .img-zone img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media all and (width<768px) {
  .facility-web {
    display: none;
  }
  .facility-mobile {
    display: flex;
    justify-content: center;
  }
  .img-card {
    width: 290px;
    height: 380px;
    display: flex;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
    background: linear-gradient(135deg, #3f3b70, #2f2c57);
    flex-direction: column;
    margin-top: 50px;
  }
  .img-zone {
    display: flex;
    object-fit: cover;
    overflow: hidden;
    height: 380px;
  }
  .img-zone img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
