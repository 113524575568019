footer {
    background-color: #414141;
    width: 100%;
    padding: 20px 0;
    position: absolute;
    bottom: 0px;
}

.footer-content, .footer-content2 {
    width: 80vw;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    color: white;
}

.footer-content2 {
    border-top: 1px solid #a5a5a5;
}

.footer-item-first span, .footer-item-second span, .footer-item2-second span, .footer-item-third span, .footer-item-fourth span {
    display: inline-block;
    padding: 0.5vw;
    color: white;
}

.footer-inquiry-title {
    font-size: 22px;
}

.footer-item-first {
    min-width: 140px;
    margin-right: 50px;
}

.footer-item-second, .footer-item-third {
    width: 40%;
}

.footer-item2-third {
    /* width: 33%; */
    display: flex;
    justify-content: flex-end; /* 오른쪽 정렬 */
    align-items: center; /* 아래쪽 정렬 */
}

.footer-item-fourth {
    width: 20%;
    text-align: right;
}

.footer-logo {
    width: 140px;
}

.family-site{
    border: none;
    background-color: #414141;
    color: white;
    box-sizing: border-box;
    float: right;
    margin-left: 5px;
    font-size: var(--font-regular);
}

.sns_logo img {
    padding: 1vw;
    cursor: pointer;
}
/* reset */
ul{
    margin: 0;
    padding: 0;
}


/* 노트북 및 PC */
@media all and (min-width: 1024px) {  
    .footer-content span {
        font-size: var(--font-small);
    }
    .family-site {
        font-size: var(--font-small);
        cursor: pointer;
    }
}

/* 태블릿 가로, 세로 */
@media all and (min-width: 768px) and (max-width: 1023px) {
    .footer-content span{
        font-size: var(--font-extra-small);
    }
    .family-site {
        font-size: var(--font-extra-small);
        cursor: pointer;
    }
    .footer-item-fourth {
        width: 22%;
    }
    .footer-item-second, .footer-item-third {
        width: 39%;
    }
    .sns_logo img {
        width: 4vw;
        cursor: pointer;
    }
   
    
}

/* 모바일 가로, 세로 */
@media all and (max-width: 767px) {
    .footer-content, .footer-content2 {
        flex-direction: column;
    }
    .footer-item {
        width: 100%;
    }
    .footer-content, .footer-content2 {
        font-size: var(--font-super-extra-small);
        border: none;
    }
    .footer-content2 {
        border-top: 1px solid #a5a5a5;
    }
    .footer-item-second, .footer-item-third, .footer-item-fourth {
        width: 100%;
    }
    .footer-item-fourth {
        text-align: left;
    }
    .family-site {
        font-size: var(--font-extra-small);
        float: none;
        cursor: pointer;
    }
    .sns_logo {
        text-align: left;
    }
    .sns_logo img {
        width: 28px; 
        cursor: pointer;
    }
    
}