.category-input {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  color: #2c2c2c;
  font-family: "Pretendard";
  padding: 10px;
}
.addBtn {
  padding: 10px 30px;
  border: 1px solid #0046ff;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  color: var(--color-black);
  font-size: var(--font-regular);
  margin-left: 15px;
}
.pw-content-header{
    width: 180px;
    font-size: var(--font-medium);
    font-weight: var(--font-weight-semiBold);
    line-height: 160%;
    color: #2c2c2c;
}
.feedback-wrong{
    padding-left: 190px;
    color : #ff0000
}
.feedback-correct{
    padding-left: 190px;
    color : #0046ff
}
.info-option-content {
  margin-right: 30px;
  display: flex;
  flex-direction: row;
}
.info-content-header{
  width: 180px;
  font-size: var(--font-medium);
  font-weight: var(--font-weight-semiBold);
  line-height: 160%;
  color: #2c2c2c;
}
.info-caption {
  font-size: var(--font-extra-small);
  font-weight: var(--font-weight-medium);
  color: #666665;
  line-height: 140%;
  padding: 5px 0 0 10px;
}
.info-title-caption{
  margin-bottom: 40px;
}
.short-zone{
  width: 80px;
}
.option-name-zone{
  width: 120px;
}
