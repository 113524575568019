.recommend-edu-zone{
  background: linear-gradient(135deg, #373C54, #1A1F3A);
  display: flex;
}

.recommend-text-zone{
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
}


/* 노트북 & PC */
@media all and (width>1023px){
  .edu-title{
    font-size: 36px;
    font-weight: var(--font-weight-medium);
  }
  .edu-recommend-text{
    margin-top: 20px;
    font-size: var(--font-large);
  }
  .recommend-edu-zone {
    display: flex;
    flex-direction: column;
    align-items: center; 
    background: #2c2c2c;
    justify-content: center;
    padding: 10vh 0;
    height: 80vh;
  }
  .seeMoreBtn{
    margin-top: 70px;
    width: 280px;
    height: 70px;
    text-align: center;
    border-radius: 70px;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    font-size: var(--font-large);
    font-weight: var(--font-weight-semiBold);
    cursor: pointer;
  }
}
/* 태블릿 가로, 세로 */
@media all and (min-width:768px) and (max-width:1023px){
  .edu-title{
    font-size: var(--font-super-extra-large);
    font-weight: var(--font-weight-medium);
  }
  .edu-recommend-text{
    margin-top: 20px;
    font-size: var(--font-large);
  }
  .recommend-edu-zone {
    display: flex;
    flex-direction: column;
    align-items: center; 
    background: #2c2c2c;
    justify-content: center;
    padding: 5vh 0;
  }
  .seeMoreBtn{
    margin-top: 70px;
    width: 280px;
    height: 70px;
    text-align: center;
    border-radius: 70px;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    font-size: var(--font-large);
    font-weight: var(--font-weight-semiBold);
    cursor: pointer;
  }
}
/* 모바일 가로, 세로 */
@media all and (width<768px){
  .edu-title{
    font-size: var(--font-super-extra-large);
    font-weight: var(--font-weight-medium);
  }
  .edu-recommend-text{
    margin-top: 20px;
    font-size: var(--font-large);
  }
  .recommend-edu-zone {
    display: flex;
    flex-direction: column;
    align-items: center; 
    background: #2c2c2c;
    justify-content: center;
    padding: 5vh 0;
  }
  .seeMoreBtn{
    margin-top: 70px;
    width: 190px;
    height: 60px;
    text-align: center;
    border-radius: 70px;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    font-size: var(--font-small);
    font-weight: var(--font-weight-semiBold);
    cursor: pointer;
  }
}