/* 공유 아이콘 스타일 */
.sns_icon {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.sns_icon:hover {
    transform: translateY(-5px);
}

.sns_icon p {
    margin-top: 8px;
    font-weight: var(--font-weight-medium);
    color: var(--color-darkGray);
}

.share-modal-sns {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

/* 링크 복사 영역 */
.link_copy {
    display: flex;
    align-items: center;
    height: 50px;
    margin-top: 30px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.link_copy_link {
    background-color: #F8F8F8;
    border: 1px solid #E5E5E5;
    height: 50px;
    width: 80%;
    padding: 0 15px;
    font-size: 0.9rem;
    color: var(--color-darkGray);
}

.link_copy_icon {
    background-color: cornflowerblue;
    width: 20%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.link_copy_icon:hover {
    background-color: var(--color-blue);
}

.link_copy_icon img {
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1);
}

/* 모달 스타일 */
.share-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    backdrop-filter: blur(3px);
}

.share-modal {
    background: white;
    border-radius: 20px;
    padding: 40px;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    animation: modalFadeIn 0.3s ease-out;
}

.share-modal-title {
    text-align: center;
    font-size: var(--font-extra-large);
    font-weight: var(--font-weight-bold);
    margin: 0 0 20px 0;
    color: var(--color-black);
}

/* 모달 닫기 버튼 */
.share-modal-btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.share-modal-btn-close:hover {
    opacity: 1;
}

.share-modal-btn-close::before,
.share-modal-btn-close::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 24px;
    background-color: #333;
    top: 50%;
    left: 50%;
}

.share-modal-btn-close::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.share-modal-btn-close::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.share-text-square {
    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

/* 모달 애니메이션 */
@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* 반응형 스타일 */
/* PC (1024px 이상) */
@media all and (width > 1023px) {
    .share-text-square {
        width: 60px;
        height: 60px;
        background-color: #a8aabc;
        border-radius: 10px;
        position: relative;
    }

    .share-modal {
        min-width: 300px;
    }

    .share-img {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .sns_icon p {
        font-size: var(--font-regular);
    }

    .sns_icon img {
        width: 70px;
        border-radius: 12px;
    }
}

/* 태블릿 (768px ~ 1023px) */
@media all and (min-width: 768px) and (max-width: 1023px) {
    .share-text-square {
        width: 60px;
        height: 60px;
        background-color: #a8aabc;
        border-radius: 10px;
        position: relative;
    }

    .share-img {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .sns_icon p {
        font-size: var(--font-small);
    }

    .sns_icon img {
        width: 70px;
        border-radius: 12px;
    }

    .share-modal {
        min-width: 300px;
    }
}

/* 모바일 (768px 미만) */
@media all and (width < 768px) {
    .share-text-square {
        width: 30px;
        height: 30px;
        background-color: #a8aabc;
        border-radius: 5px;
        position: relative;
    }

    .share-img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .sns_icon img {
        width: 50px;
        border-radius: 12px;
    }
}