/* 카드 기본 스타일 */
.course-card {
    position: relative;
    margin-bottom: 20px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #fff;
}

.course-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* 이미지 관련 스타일 */
.image-wrap {
    position: relative; 
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.course-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

/* 라벨 스타일 */
.course-label {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: 1.5px solid var(--color-blue);
    background-color: white;
    color: var(--color-blue);
    padding: 6px 12px;
    border-radius: 5px;
    font-weight: 500;
    transition: all 0.2s ease;
}  

.course-closed-label {
    background-color: #686868;
    border-color: #686868;
    color: white;
}

.course-upcoming-label {
    border: 1.5px solid #FF9800;
    color: #FF9800;
    background-color: white;
}

/* 컨텐츠 스타일 */
.course-content {
    padding: 0px 5px 5px;
}

.course-title {
    margin: 10px 0px;
    font-weight: var(--font-weight-medium);
}

.course-time {
    margin: 0;
    color: #777;
    display: inline-block;
}

/* 반응형 스타일 */
/* 노트북 & PC (1024px 이상) */
@media all and (min-width: 1024px) {
    .course-card {
        width: 23.5%;
    }
    
    .course-card:nth-child(4n) {
        margin-right: 0;
    }

    .course-time {
        font-size: 18px;
    }

    .course-label {
        font-size: 18px;
    }  

    .course-title {
        font-size: 20px;
    }
}

/* 태블릿 (768px - 1023px) */
@media all and (min-width: 768px) and (max-width: 1023px) {
    .course-card {
        width: 31%;
    }

    .course-card:nth-child(3n) {
        margin-right: 0;
    }

    .course-time {
        font-size: 16px;
    }

    .course-label {
        font-size: 16px;
    }  

    .course-title {
        font-size: 18px;
    }
}

/* 모바일 (768px 미만) */
@media all and (max-width: 767px) {
    .course-card {
        width: 48%;
    }
    
    .course-card:nth-child(2n) {
        margin-right: 0;
    }
    
    .course-time {
        font-size: 11px;
    }

    .course-label {
        font-size: 10px;
    }  

    .course-title {
        font-size: 16px;
    }
}
