html, body {
    height: 100%;
    margin: 0;
}

.page-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* 노트북 및 PC */
@media all and (min-width: 1024px) {  
    .page-content {
        margin-bottom: 300px;
    }
    
    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 300px;
        box-sizing: border-box; 
    }
   
}

/* 태블릿 가로, 세로 */
@media all and (min-width: 768px) and (max-width: 1023px) {

    .page-content {
        padding-bottom: 300px; /* 푸터 높이와 맞추기 */
    }
    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 300px;
        box-sizing: border-box; 
    }

    
}

/* 모바일 가로, 세로 */
@media all and (max-width: 767px) {
    .page-content {
        padding-bottom: 330px; /* 푸터 높이와 맞추기 */
    }
    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 330px;
        box-sizing: border-box; 
    }

    
}