.app {
  display: flex;
  margin: 0;
  height: 100%;
  min-height: 100vh;
  background-color: #f6f8f9;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 18vw;
  min-width: 250px;
  height: 100%;
  background-color: #757575;
  color: #fff;
  z-index: 999;
}

.logo {
  padding: 2vh 1vw 0 1vw;
}

.fzlogo {
  width: 9vh;
}
.menu {
  margin-top: 80px;
}

.menu-item h3, .submenu, .fzlogo {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.menu-item h3 {
  display: flex;
  font-size: var(--font-regular);
  cursor: pointer;
  padding: 2vh 1vw 1.5vh 1vw;
  margin: 0;
  font-weight: var(--font-weight-medium);
}

.submenu {
  padding: 0vh 2vw 0vh 2vw;
  background-color: #a4a4a4;
  margin: 0;
  li {
    font-size: var(--font-regular);
    padding: 1vh 0.5vw;
    list-style: none;
    /* cursor: pointer; */
  }
}
.submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.menu-item.active h3 {
  background-color: #a4a4a4;
}

.main-content {
  flex: 1;
  padding: 2vh;
  width: 60vw;
  min-height: 85%;
  background-color: #fff;
  box-shadow: 5px 5px 20px #cccecf;
  border-radius: 20px;
  margin-bottom: 20px;
}

.form {
  display: grid;
  gap: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.sidebar-icon {
  padding-right: 0.5vw;
  width: 20px;
}

.content-box {
  margin: 2vh 0 0 13vw;
}

.pageName {
  font-size: var(--font-large);
  margin-bottom: 2vh;
  color: #757575;
  font-weight: var(--font-weight-semiBold);
}

.menu-link {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.menu-item.active h3 .menu-link {
  color: #2c2c2c; /* 활성화된 메뉴의 글씨 색을 검정색으로 변경 */
}

.submenu li.selected .menu-link {
  color: #2c2c2c; /* 선택된 서브메뉴 항목의 글씨 색을 검정색으로 변경 */
}

.submenu li.selected {
  font-weight: var(--font-weight-semiBold); /* 선택된 서브메뉴 항목을 강조 */
}
.bottom-menu {
  padding: 2vh 1vw;
  background-color: #757575;
  position: absolute;
  bottom: 20px;
}

.bottom-menu .menu-item h3 {
  padding: 1vh 0;
}

.bottom-menu .menu-link {
  color: #fff;
  text-decoration: none;
}


.menu-item h3:hover,
.menu-item h3:hover .menu-link,
.menu-link:hover {
  color: #2c2c2c;
}