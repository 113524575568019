header.main-header {
    /* background-color: #414141; */
    background-color: rgba(65, 65, 65, 0);
}

header.scrolled {
    background-color: rgba(65, 65, 65, 0.6); /* Main일 때의 초기 배경 색상 설정하기 */
    transition: background-color 0.3s ease;
}
header li{
    cursor: pointer;
    color: white;
}
header li:hover{
    color: #b79b60;
}
.header-container{
    width: 80vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-black);
    font-weight: var(--font-weight-regular);
    margin: 0 auto;
}
.header-menulist {
    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.header-menulist li.active, .modal-menulist li.active {
    font-weight: var(--font-weight-bold); /* 활성화된 메뉴 항목의 색상 */
}
/* 햄버거 메뉴 버튼 스타일 */
.toggle {
    position: relative;
    width: 30px;
    height: 24px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.toggle span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}
.toggle span:nth-of-type(1) {
    top: 0;
}
.toggle span:nth-of-type(2) {
    top: 10px;
}
.toggle span:nth-of-type(3) {
    bottom: 0;
}
/* 요기 아래 3개 */
.toggle.active span:nth-of-type(1) {
    transform: translateY(10px) rotate(45deg);
    background-color: var(--color-black);
}
.toggle.active span:nth-of-type(2) {
    opacity: 0;
    background-color: var(--color-black);
}
.toggle.active span:nth-of-type(3) {
    transform: translateY(-10px) rotate(-45deg);
    background-color: var(--color-black);
}
/* 모달 스타일 */
.header-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 1000;
}
.header-modal-content{
    position: relative;
    background-color: white;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    z-index: 1000;
}
.close-btn {
    width: 25px;
    height: 28px;
    cursor: pointer;
    /* position: relative; */
}
.close-btn:before, .close-btn:after{
    position: absolute; 
    content: ' ';
    height: 30px;
    width: 3px;
    background-color: var(--color-black);
    right: 10px;
}
.close-btn:before{
    transform: rotate(45deg);
}
.close-btn:after{
    transform: rotate(-45deg);
}
.modal-menulist {
    list-style: none;
    /* 패딩을 20vw로 주니까 862px부터 1024까지 점차 하얀색 헤더의 높이가 낮아짐 */
    /* 스마트폰 최소 높이 px이 568px이므로 vh로 해도 문제 없음 */
    padding: 11vh;
    flex-direction:column;
}
.modal-menulist li {
    font-size: 1.5rem;
    margin: 40px 0;
    cursor: pointer;
    color: var(--color-black);

    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
} 
.main-logo, .main-logo_blue {
    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.header-modal-content img{
    height: 40px;
}
.modal-menulist li:hover {
    color: var(--color-blue);
}
.header-modal-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    position: relative;
}
/* 노트북 & PC */
@media all and (width>1023px){  
    header {
        background-color: #414141;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
        height: 90px;
        position: fixed;
        width: 100vw;
        z-index: 99; 
        top: 0;
        transition: background-color 0.3s ease;
    }
    header nav {
        font-size: var(--font-large);
        font-weight: var(--font-weight-medium);
    }
    header ul {
        list-style: none;
        display: flex;
        margin: 0;
    }
    header li{
        margin-left: 2vw;
    } 
    .toggle{
        display: none;
    }
    .header-modal{
        display: none;
    }
    .header-container img{
        cursor: pointer;
        height: 40px;
    }
    .header-modal img {
        height: 40px;
    }
    .header-modal-top{
        height: 90px;
    }
}
/* 태블릿 가로, 세로 */
@media all and (min-width:768px) and (max-width:1023px){
    header {
        background-color: #414141;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
        height: 50px;
        position: fixed;
        width: 100vw;
        z-index: 99; 
        top: 0;
        transition: background-color 0.3s ease;
    }
    .header-menulist {
        display: none;
    }
    .toggle {
        display: block;
        z-index: 10000;
    }
    .header-container img{
        cursor: pointer;
        height: 35px;
    }
    .header-modal img {
        height: 35px;
    }
    .header-modal-top{
        height: 50px;
    }
}
/* 모바일 가로, 세로 */
@media all and (width<768px){
    header {
        background-color: #414141;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
        height: 50px;
        position: fixed;
        width: 100vw;
        z-index: 99; 
        top: 0;
        transition: background-color 0.3s ease;
    }
    .header-menulist {
        display: none;
    }
    .toggle {
        display: block;
        z-index: 10000;
    }
    .header-container img{
        cursor: pointer;
        height: 30px;
    }
    .header-modal img {
        height: 30px;
    }
    .header-modal-top{
        height: 50px;
    }
}
