.story-zone {
  background-color: #fff;
  padding: 5vh 10vw;
}
.story-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.story-title {
  font-size: 44px;
  font-weight: var(--font-weight-semiBold);
  align-items: center;
  display: flex;
}
.main-title-logo {
  margin-right: 20px;
}
.story-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
}
.story-intro {
  width: 600px;
  min-width: 400px;
  height: 820px;
  position: relative;
  background-size: cover;
  background-position: center;
}
.post-content{
  cursor: pointer;
}
.story-intro::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.intro-text {
  position: absolute;
  margin-top: 30px;
  top: 20%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  font-weight: var(--font-weight-bold);
  text-align: left;
  line-height: 160%;
  z-index: 2;
}
.more-story {
  position: absolute;
  margin-top: 30px;
  bottom: 0;
  left: 20%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: var(--font-large);
  font-weight: var(--font-weight-semiBold);
  text-align: left;
  line-height: 160%;
  z-index: 2;
  cursor: pointer;

  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.story-post {
  width: 50vw;
}
.post-img {
  width: 50%;
  max-width: 400px;
  min-width: 200px;
  height: 200px;
  object-fit: cover;
}
.post-txt {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.post-title {
  font-size: var(--font-extra-large);
  font-weight: var(--font-weight-semiBold);
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.posting{
  width: 50%;
}
.post {
  margin-top: 20px;
  width: 20vw;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.postDay {
  margin: 20px 0;
}
/* 노트북 & PC */
@media all and (width>1023px) {
  .mobile-story-zone {
    display: none;
  }
}

/* 태블릿 가로, 세로 */
@media all and (min-width: 768px) and (max-width: 1023px) {
  .story-intro {
    display: none;
  }
  .story-content {
    display: none;
  }
  .story-header {
    display: none;
  }
  .mobile-story-zone {
    background-color: #fff;
    padding: 0 10vw;
  }
  .mobile-story-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .mobile-story-title {
    font-size: 36px;
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    display: flex;
  }
}

/* 모바일 가로, 세로 */
@media all and (width<768px) {
  .story-intro {
    display: none;
  }
  .story-content {
    display: none;
  }
  .story-header {
    display: none;
  }
  .mobile-story-zone {
    background-color: #fff;
  }
  .mobile-story-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .mobile-story-title {
    font-size: var(--font-extra-large);
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    display: flex;
  }
}
