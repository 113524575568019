
.address-button{
  cursor: pointer;
}
/* 노트북 & PC */
@media all and (width>1023px){  
    .address-search{
        margin: 0px 10%;
        text-align: left;
    }
}
/* 태블릿 가로, 세로 */
@media all and (min-width:768px) and (max-width:1023px){
    .address-search{
        margin: 0px 10%;
        text-align: left;
    }
}
@media all and (width < 768px) {
    .address-search {
        width: 100%;
        max-width: 400px;
        margin: 0;
    }
    
    /* DaumPostcode 컴포넌트의 iframe 스타일 */
    .address-search div {
        width: 100% !important;
    }

}

/* 모달 */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
   
  .modal {
    background: white;
    border-radius: 8px;
    padding: 30px;
    position: relative;
  }
  .modal div{
    margin-top: 30px;
  }
  .modal-title{
    text-align: center;
    font-size: var(--font-extra-large);
    font-weight: var(--font-weight-bold);
    margin: 0;
  }
  .modal-close-btn {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    width: 30px;
    height: 25px;
}
/* 노트북 & PC */
@media all and (width>1023px){  
  .modal {
    min-width: 400px;
  }
}
/* 태블릿 가로, 세로 */
@media all and (min-width:768px) and (max-width:1023px){
  .modal {
    min-width: 400px;
  }
}

/* 모바일 가로, 세로 */
@media all and (width<768px){
  .modal-title{
    font-size: var(--font-large);
    font-weight: var(--font-weight-bold);
  }
  .modal {
    max-width: 400px;
    width: 90%;
    box-sizing: border-box;
}

  .modal > div {
      max-width: 100% !important;
  }

}
