
.youtube-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 10vh 10vw;
}
.youtube-title {
  font-size: 44px;
  font-weight: var(--font-weight-semiBold);
  align-items: center;
  display: flex;
}
.youtube-zone {
  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@media all and (width>1023px) {
  iframe {
    width: 100%;
    height: 80vh;
  }
  .mobile-youtube-header {
    display: none;
  }
  .youtube-zone {
    background-color: #fff;
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .youtube-header {
    display: none;
  }
  .video-zone{
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 40vh;
  }
  .mobile-youtube-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .mobile-youtube-title {
    font-size: 36px;
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    display: flex;
  }
  .youtube-zone {
    background-color: #fff;
    padding-top: 10vh;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media all and (width<768px) {
  .youtube-header {
    display: none;
  }
  .video-zone{
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 30vh;
  }
  .mobile-youtube-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .mobile-youtube-title {
    font-size: var(--font-extra-large);
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    display: flex;
  }
  .youtube-zone {
    background-color: #fff;
    padding-top: 10vh;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
