.main-content {
  padding: 40px !important;
  margin-bottom: 30px;
  overflow: hidden;
  min-width: 900px;
}

.text-zone,
.link-zone {
  display: flex;
  padding-bottom: 20px;
  padding-left: 10px;
  border-bottom: 1px solid #a4a4a4;
  margin-bottom: 20px;
}
.add-training-zone {
  padding-bottom: 20px;
  border-bottom: 1px solid #a4a4a4;
  margin-bottom: 20px;
}

.input-image-zone {
  display: flex;
  padding-bottom: 20px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.portfolio-image-zone {
  display: flex;
  padding-bottom: 20px;
  padding-left: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #a4a4a4;
}

.image-zone {
  border-bottom: 1px solid #a4a4a4;
  margin-bottom: 20px;
}

.content-header {
  width: 120px;
  font-size: var(--font-medium);
  font-weight: var(--font-weight-semiBold);
  line-height: 160%;
  color: #2c2c2c;
}
.agreement-header {
  width: 100%;
  font-size: var(--font-medium);
  font-weight: var(--font-weight-semiBold);
  line-height: 160%;
  color: #2c2c2c;
}

.inner-text-zone {
  display: flex;
  padding-bottom: 20px;
  padding-left: 10px;
}

.textbox {
  width: 82%;
  height: 200px;
  padding: 20px;
  font-size: var(--font-medium);
  border: 1px solid #d9d9d9;
  font-family: "Pretendard";
  color: #2c2c2c;
  border-radius: 6px;
}

.image-container {
  display: flex;
  flex-direction: column;
}

.add-web-image,
.add-mobile-image {
  margin: 0 0 10px 0;
}

.image-preview-box {
  width: 500px;
  margin: 0 0 20px 0;
}

.image-preview {
  width: 100%;
}

.link-zone input {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  color: #2c2c2c;
  font-family: "Pretendard";
  padding: 10px;
}
.add-training-zone .text-input {
  width: 40vw;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  font-family: "Pretendard";
  color: #2c2c2c;
  padding: 10px;
}
.calc-input {
  width: 250px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  color: #2c2c2c;
  font-family: "Pretendard";
  font-size: var(--font-medium);
  padding: 10px;
}
.img-tag {
  font-size: var(--font-regular);
  font-weight: var(--font-weight-medium);
  margin: 5px 0;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.submitBtn {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #0046ff;
  color: #fff;
  font-size: var(--font-regular);
}
.deleteBtn {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ff0000;
  margin-left: 15px;
  font-size: var(--font-regular);
  font-weight: var(--font-weight-medium);
}
.pdf-downloadBtn {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #eff0f2;
  margin-left: 15px;
  color: #fff;
  font-size: var(--font-regular);
  color: var(--color-black);
  font-weight: var(--font-weight-semiBold);
}

.title {
  margin: 0 0 20px 10px;
  font-size: var(--font-extra-large);
  font-weight: var(--font-weight-semiBold);
}
.subtitle {
  font-size: var(--font-medium);
  font-weight: var(--font-weight-medium);
  color: #a5a5a5;
  margin: 0 0 20px 10px;
}
hr {
  margin-bottom: 20px;
}

.option-zone {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding-left: 10px;
}
.down-option-zone {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;
  padding-left: 10px;
}
.option-content {
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.option-subtitle {
  margin-right: 10px;
  font-size: var(--font-medium);
  font-weight: var(--font-weight-semiBold);
  color: var(--color-black);
}

.drop-down-menu {
  margin-left: 10px;
  padding: 8px;
  border-radius: 6px;
  font-size: var(--font-regular);
  color: var(--color-lightFontGray);
  font-weight: var(--font-regular);
  border: 1px solid var(--color-lightLineGray);
}
.long-drop-down-menu {
  margin-bottom: 20px;
  padding: 10px;
  width: 40vw;
  border-radius: 6px;
  font-size: var(--font-regular);
  color: var(--color-lightFontGray);
  font-weight: var(--font-regular);
  border: 1px solid var(--color-lightLineGray);
}

.list-zone {
  margin-bottom: 20px;
}

.list-table {
  width: 100%;
  border-collapse: collapse;
  font-size: var(--font-medium);
  color: var(--color-black);
}
.list-table th,
.list-table td {
  height: 55px;
  padding: 12px;
  border-bottom: 1px solid #e8e7e7;
}
.list-table th {
  background-color: #f6f8f9;
  font-weight: var(--font-weight-semiBold);
}
.list-table tbody {
  text-align: center;
}
.innerText {
  cursor: pointer;
}
.pagination-zone {
  margin-top: 50px;
  text-align: center;
  position: relative;
  transform: translateY(-100%);
}

.pagination-zone button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background: none;
  cursor: pointer;
}
.list-table input {
  -webkit-transform: scale(1.4);
  cursor: pointer;
}

.content-title {
  border: 1px solid var(--color-lightLineGray);
  border-radius: 6px;
  height: 14px;
  padding: 10px;
  color: var(--color-lightFontGray);
}
.long-title {
  border: 1px solid var(--color-lightLineGray);
  border-radius: 6px;
  height: 14px;
  padding: 10px;
  color: var(--color-lightFontGray);
  width: 40vw;
}
.radio-button {
  font-size: var(--font-medium);
}
.date-input-button {
  display: inline-block;
  width: 250px;
  height: 46px;
  padding: 10px;
  border: 1px solid var(--color-lightLineGray);
  border-radius: 6px;
  background-color: #fff;
  color: var(--color-lightFontGray);
  font-size: var(--font-regular);
  text-align: left;
  cursor: pointer;
}

/* 캘린더 기본 컨테이너 */
.react-calendar {
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 25%;
}

.react-calendar__tile {
  padding: 15px 0;
  font-size: 16px;
  color: var(--color-black);
  border: none;
}

.react-calendar__tile--active {
  background-color: #007bff;
  color: white;
}

.react-calendar__tile--now {
  background-color: #f3b406;
  color: #333;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
}

.react-calendar__navigation button {
  background: none;
  border: none;
  font-size: 18px;
  color: #333;
  cursor: pointer;
}

.react-calendar__navigation button:hover {
  color: #007bff;
}

.react-calendar__month-view__days__day--weekend {
  color: #ff0000;
}

.react-calendar__tile--range {
  background-color: #007bff33;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background-color: #007bff;
  color: white;
}

.calendar-icon {
  margin-left: 10px;
  height: 44px;
}

.agreement-zone {
  width: 100%;
}
.agreement-content {
  margin-bottom: 20px;
}
.essential {
  color: #ff0000;
}

.note-zone {
  border: 1px solid var(--color-lightLineGray);
  font-size: var(--font-regular);
  border-radius: 6px;
  color: var(--color-lightFontGray);
  padding: 6px;
  width: 6vw;
}
.all-download {
  padding: 10px;
  border-radius: 6px;
  border: none;
  font-weight: var(--font-weight-semiBold);
  font-size: var(--font-regular);
  color: var(--color-black);
  cursor: pointer;
}
.score-zone {
  border: 1px solid var(--color-lightLineGray);
  font-size: var(--font-regular);
  border-radius: 6px;
  color: var(--color-lightFontGray);
  padding: 6px;
  width: 30px;
  font-size: var(--font-small);
  text-align: center;
}
.category-table{
  cursor: pointer;
}
.category-table-not-pointer{
  cursor: auto;
}
.modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  width: 60%;
  max-width: 950px;
  height: 75%;
  max-height: 80vh;
  padding: 30px;
  border-radius: 10px;
  overflow-y: auto;
}
.job-modal-content {
  background-color: #ffffff;
  width: 60%;
  max-width: 950px;
  height: 55%;
  max-height: 80vh;
  padding: 30px;
  border-radius: 10px;
  overflow-y: auto;
}
.close-area {
  float: right;
}
.closeBtn {
  margin-left: auto;
  cursor: pointer;
}
.modal-header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.modal-header-name {
  font-size: var(--font-super-extra-large);
  font-weight: var(--font-weight-bold);
}
.modal-header-score {
  font-size: var(--font-large);
  font-weight: var(--font-weight-medium);
}
.modal-header-pass {
  background-color: #3b7de0;
  font-size: var(--font-large);
  font-weight: var(--font-weight-medium);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
}
.modal-personal-content,
.modal-info-content {
  font-size: var(--font-large);
  font-weight: var(--font-weight-medium);
  color: var(--color-black);
  border: 1px solid var(--color-lightLineGray);
  padding: 20px;
  border-radius: 10px;
  margin-top: 50px;
}
.modal-personal-content th,
.modal-info-content th {
  text-align: left;
  padding: 10px 200px 10px 0;
}
.modal-personal-content td {
  width: 300px;
}
.modal-info-content td {
  width: 500px;
}
.profile img {
  width: 180px;
  height: 270px;
}
.motivate-zone {
  width: 500px;
  padding: 10px;
  border: 1px solid var(--color-lightLineGray);
  font-size: var(--font-medium);
  font-family: "Pretendard";
  border-radius: 6px;
  height: 50px;
}
.agree-checkbox {
  display: flex;
  flex-direction: column;
}
.modal-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.qnaStat.answered {
  color: #a4a4a4;
}

.qnaStat.pending {
  color: blue;
}
.ad-zone {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.input-zone-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.empText {
  cursor: pointer;
}
.empText:hover{
  text-decoration: underline;
}
