.main-content {
  padding: 40px !important;
  margin-bottom: 30px;
  overflow: hidden;
  min-width: 900px;
}

.caption-address {
  width: 250px;
}
.text-zone,
.link-zone {
  display: flex;
  padding-bottom: 20px;
  padding-left: 10px;
  border-bottom: 1px solid #a4a4a4;
  margin-bottom: 20px;
}

.input-image-zone2 {
  display: flex;
  padding-bottom: 20px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.input-image-zone {
  display: flex;
  padding-bottom: 20px;
  padding-left: 10px;
  margin-bottom: 20px;
}

.image-zone {
  border-bottom: 1px solid #a4a4a4;
  margin-bottom: 20px;
}

.content-header {
  width: 120px;
  font-size: var(--font-medium);
  font-weight: var(--font-weight-semiBold);
  line-height: 160%;
  color: #2c2c2c;
}

.colunm-content-header {
  margin-bottom: 10px;
  width: 150px;
  font-size: var(--font-medium);
  font-weight: var(--font-weight-semiBold);
  line-height: 160%;
  color: #2c2c2c;
}

.inner-text-zone {
  display: flex;
  padding-bottom: 20px;
  padding-left: 10px;
}

.textbox {
  width: 82%;
  height: 200px;
  padding: 20px;
  font-size: var(--font-medium);
  border: 1px solid #d9d9d9;
  font-family: "Pretendard";
  color: #2c2c2c;
  border-radius: 6px;
}

.image-container {
  display: flex;
  flex-direction: column;
}

.add-web-image,
.add-mobile-image {
  margin: 0 0 10px 0;
}

.image-preview-box {
  width: 500px;
  margin: 0 0 20px 0;
}

.image-preview {
  width: 100%;
}

.link-zone input {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  color: #2c2c2c;
  font-family: "Pretendard";
  padding: 10px;
}

.footer-zone input {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  width: 15vw;
  color: #2c2c2c;
  font-family: "Pretendard";
  padding: 10px;
}
.img-tag {
  font-size: var(--font-regular);
  font-weight: var(--font-weight-medium);
  margin: 5px 0;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.submitBtn {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #0046ff;
  color: #fff;
  font-size: var(--font-regular);
}
.etc-title{
  width: 120px;
    font-size: var(--font-medium);
    font-weight: var(--font-weight-regular);
}
.etc-blog-title, .etc-yb-title{
  width: 100px;
  font-size: var(--font-medium);
  font-weight: var(--font-weight-regular);
}

.site-zone{
    display: flex;
    width: 65vw;
}

.input-container{
    display: flex;
    flex-direction: row;
}

.etc-title{
    margin-right: 15px;
}
.etc-blog-title{
  margin-right: 30px;
}

.etc-yb-title{
  margin-right: 36px;
}

.input-container:nth-child(2){
    margin-left: 50px;
}
.site-delete{
  margin-left: 30px;
  font-size: var(--font-medium);
  color: red;
  cursor: pointer;
}

.add-site-btn{
    margin-bottom: 20px;
    cursor: pointer;
    font-size: var(--font-medium);
    font-weight: var(--font-weight-regular);
}
.image-content{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #a4a4a4;
  margin-bottom: 20px;
  padding-left: 10px;
}

.add-image-btn{
  margin-bottom: 20px;
  font-size: var(--font-regular);
  font-weight: var(--font-weight-semiBold);
  color: #757575;
  border: none;
  background: none;
  cursor: pointer;
}
.delete-image-btn{
  margin-bottom: 20px;
  font-size: var(--font-regular);
  font-weight: var(--font-weight-semiBold);
  color: #ff0000;
  border: none;
  background: none;
  cursor: pointer;
}