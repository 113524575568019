.success-container{
    width: 80vw;
    margin: 90px auto;
    animation: fadeIn 0.8s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.success-text{
   max-width: 1000px;
   margin: 0 auto;
}
.success-message{
    text-align: center;
    margin-bottom: 50px;
    font-size: var(--font-large);
    font-weight: var(--font-weight-semiBold);
    color: var(--color-blue);
}
.goMain-btn-wrap{
    text-align: center;
}
.goMain-btn{
    border: none;
    color: white;
    background-color: var(--color-blue);
    font-size: var(--font-regular);
    height: 50px;
    border-radius: 10px;
    margin: 50px auto 0;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(50, 106, 255, 0.2);
}
.goMain-btn:hover{
    background-color: #2857d8;
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(50, 106, 255, 0.3);
}

/* 성공 아이콘 */
.success-icon-container {
    position: relative;
    width: 5em;
    height: 5em;
    margin: 30px auto;
    animation: scaleIn 0.5s ease-out 0.3s both;
}

@keyframes scaleIn {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

.success-icon {
    width: 5em;
    height: 5em;
    border-radius: 50%;
    background-color: var(--color-blue);
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0 4px 12px rgba(50, 106, 255, 0.3);
}
.success-icon::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    width: 30%;
    height: 55%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-70%) rotateZ(40deg);
    border-right: 5px solid white;
    border-bottom: 5px solid white;
    animation: checkmark 0.4s ease-in-out 0.8s both;
}
@keyframes checkmark {
    from {
        opacity: 0;
        transform: translateX(-50%) translateY(-70%) rotateZ(40deg) scale(0.5);
    }
    to {
        opacity: 1;
        transform: translateX(-50%) translateY(-70%) rotateZ(40deg) scale(1);
    }
}
.training-wrap{
    border: solid 1px var(--color-lightLineGray);
    box-shadow: 0 8px 24px rgba(72, 75, 108, 0.1);
    background-color: white;
    border-radius: 20px;
    max-width: 1000px;
    box-sizing: border-box;
    margin: 0 auto;
    transition: transform 0.3s ease;
}

.info-training{
    margin: 0 auto;
    display: flex;
    align-items: start;

}
.info-training-title{
    text-align: left;
    font-weight: var(--font-weight-semiBold);
    font-size: var(--font-large);
    color: var(--color-blue);
    margin: 20px 0;
}
.info-training{  
    padding: 10px 0;
    border-bottom: 1px solid rgba(72, 75, 108, 0.1);
}
.info-training:last-child{
    border-bottom: none;
}
.info-training label {  
    display: inline-block;
    margin: 0;
    width: 40%;
    line-height: 30px;
    box-sizing: border-box;
    font-weight: var(--font-weight-semiBold);
    color: #666;
}
.info-text{
    display: inline-block;
    margin: 0;
    line-height: 30px;
    box-sizing: border-box;
    width: 50%;
    color: #333;
}
.photo-file-wrap{
    width: 180px;
    height: 240px;
    position: relative;
    overflow: hidden;
}
.photo-file{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* 노트북 & PC */
@media all and (width>1023px){
    .goMain-btn{
        width: 100%;
        min-width: 615px;
        max-width: 1000px;
    }
    .info-training label{
        font-size: var(--font-regular);
    }
    .info-text{
        font-size: var(--font-regular);
    }
    .training-wrap{
        padding: 10px 30px;
    }
    .photo-file-wrap{
        width: 180px;
        height: 240px;
        margin-bottom: 40px;
    }
}
/* 태블릿 가로, 세로 */
@media all and (min-width:768px) and (max-width:1023px){
    .goMain-btn{
        width: 100%;
        min-width: 615px;
        max-width: 1000px;
    }
    .info-training label{
        font-size: var(--font-small);
    }
    .info-text{
        font-size: var(--font-small);
    }
    .training-wrap{
        padding: 5px 25px;
    }
    .photo-file-wrap{
        width: 150px;
        height: 200px;
        margin-bottom: 30px;
    }
}
/* 모바일 가로, 세로 */
@media all and (width<768px){
    .goMain-btn{
        width: 100%;
    }
    .info-training label{
        font-size: var(--font-extra-small);
    }
    .info-text{
        font-size: var(--font-extra-small);
    }
    .training-wrap{
        padding: 0px 15px;
    }
    .photo-file-wrap{
        width: 120px;
        height: 160px;
        margin-bottom: 20px;
    }
}

