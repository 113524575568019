.notice-zone {
  background-color: #fff;
  padding: 15vh 10vw;
}
.notice-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.notice-title {
  font-size: 44px;
  font-weight: var(--font-weight-semiBold);
  align-items: center;
  display: flex;
}
.more-notice {
  cursor: pointer;
  color: #8d8d8d;
  font-size: var(--font-large);
  font-weight: var(--font-weight-medium);

  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.notice-main {
    width: 80vw;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: #F9FAFC;
    margin-top: 20px;
    cursor: pointer;
}
.notice-main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.notice-main-title {
    margin-left: 25px;
    font-size: var(--font-medium);
    font-weight: var(--font-weight-medium);
    flex-grow: 1;
}
.notice-main-toggle-button {
    margin-right: 35px;
    margin-bottom: 15px;
    cursor: pointer;
}
.notice-main-toggle-button span::after {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 3px solid #121212;
    border-right: 3px solid #121212;
    position: absolute;
    transition: transform 0.3s;
    margin-top: 5px;
}
.notice-main-toggle-open::after {
    transform: rotate(315deg);
}

.notice-main-toggle-close::after {
    transform :rotate(135deg);
}
@media all and (width>1023px) {
 .mobile-add-notice, .mobile-notice-header{
  display: none;
 }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .notice-header {
    display: none;
  }
  .mobile-notice-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .mobile-notice-title {
    font-size: 36px;
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    display: flex;
  }
  .mobile-add-notice{
    text-align: center;
    margin: 50px 0;
    font-size: var(--font-medium);
    font-weight: var(--font-weight-semiBold);
}
}

@media all and (width<768px) {
  .notice-header {
    display: none;
  }
  .mobile-notice-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .mobile-notice-title {
    font-size: var(--font-extra-large);
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    display: flex;
  }
  .mobile-add-notice{
    text-align: center;
    margin: 50px 0;
    font-size: var(--font-regular);
    font-weight: var(--font-weight-semiBold);
}
}