/* 기본 섹션 스타일 */
.info-section {
    margin-bottom: 45px;
}

/* 사진 관련 스타일 */
.photo-size {
    color: #A5A5A5;
}

.photo-file {
    width: 100%;
    height: 100%;
}

.photo-square {
    background-color: #EEEEEE;
    border-radius: 5%;
    z-index: 1;
    width: 100%;
    height: 100%;
}

/* 버튼 스타일 */
.insert-button {
    background-color: var(--color-black);
    color: white;
    border: none;
}

.delete-button {
    border: 1px solid var(--color-black);
    color: var(--color-black);
}

/* 입력 필드 스타일 */
.email, .phone {
    width: 15% !important;
}

/* 성별 선택 스타일 */
.gender {
    display: flex;
    align-items: center;
}

.gender input {
    width: 5%;
    height: 100% !important;
    margin: 0 1vw;
}

/* 주소 입력 스타일 */
.address div {
    display: inline-block;
}

.address p:nth-child(1) {
    height: 120px !important;
    line-height: 120px !important;
}

/* 서명 섹션 스타일 */
.sign-section {
    text-align: center;
    font-weight: var(--font-weight-bold);
    margin-top: 10vh;
}

.sign-section div {
    margin: 50px 0px;
}

/* 하이라이트 효과 */
.photo-wrap.highlight {
    border: 2px solid red;
}

.photo-button{
    cursor: pointer;
}
/* 반응형 스타일 - 노트북 & PC (1024px 이상) */
@media all and (width > 1023px) {
    /* 기본 정보 레이아웃 */
    .basic-info {
        display: flex;
    }

    .info-title p:nth-child(2) {
        font-size: var(--font-small);
    }

    /* 사진 영역 스타일 */
    .photo {
        width: 35%;
        text-align: center;
        padding: 2%;
        max-width: 230px;
    }

    .info-normal {
        width: 75%;
    }

    .info-normal p:nth-child(1) {
        width: 8vw;
    }

    /* 사진 크기 및 레이아웃 */
    .photo-size {
        font-size: 1rem;
    }

    .photo-wrap {
        width: 100%;
        height: 0;
        padding-bottom: 133.33%;
        margin: 0 auto;
        position: relative;
    }

    .photo-file, .photo-square {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    /* 사진 버튼 스타일 */
    .photo-button-wrap {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

    .photo-button {
        width: 48%;
        height: 40px;
        border-radius: 0.5vw;
        font-size: var(--font-small);
    }

    /* 카메라 아이콘 위치 */
    .icon-camera {
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /* 셀렉트 박스 스타일 */
    .info-content select {
        height: 35px;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
    }
}

/* 반응형 스타일 - 태블릿 (768px ~ 1023px) */
@media all and (min-width: 768px) and (max-width: 1023px) {
    /* 기본 정보 레이아웃 */
    .basic-info {
        display: flex;
    }

    .info-title p:nth-child(2) {
        font-size: var(--font-small);
    }

    /* 사진 영역 스타일 */
    .photo {
        width: 35%;
        text-align: center;
        padding: 3%;
    }

    .info-normal {
        width: 75%;
    }

    /* 사진 크기 및 레이아웃 */
    .photo-size {
        font-size: 0.9rem;
    }

    .photo-wrap {
        width: 100%;
        height: 0;
        padding-bottom: 133.33%;
        margin: 0 auto;
        position: relative;
    }

    .photo-file, .photo-square {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    /* 사진 버튼 스타일 */
    .photo-button-wrap {
        display: flex;
        justify-content: space-between;
    }

    .photo-button {
        width: 48%;
        height: 35px;
        border-radius: 0.5vw;
        font-size: 13px;
    }

    /* 카메라 아이콘 위치 */
    .icon-camera {
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /* 셀렉트 박스 스타일 */
    .info-content select {
        height: 35px;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
    }
}

/* 반응형 스타일 - 모바일 (768px 미만) */
@media all and (width < 768px) {
    /* 타이틀 폰트 크기 */
    .info-title p:nth-child(2) {
        font-size: var(--font-extra-small);
    }

    /* 사진 영역 레이아웃 */
    .photo {
        display: flex;
        align-items: end;
        padding: 5% 0;
        gap: 20px;
        border-bottom: 1px solid var(--color-black);
    }

    /* 사진 크기 및 레이아웃 */
    .photo-wrap {
        width: 90px;
        height: 120px;
        position: relative;
    }

    .photo-size {
        font-size: 12px;
        margin-top: 0;
    }

    /* 사진 버튼 스타일 */
    .photo-button-wrap {
        display: flex;
        justify-content: space-between;
    }

    .photo-button {
        width: 70px;
        height: 30px;
        margin-right: 5px;
        border-radius: 5px;
        font-size: var(--font-extra-small);
    }

    /* 주소 입력 필드 */
    #address {
        margin: 10px 0;
    }

    /* 카메라 아이콘 위치 */
    .icon-camera {
        position: absolute;
        z-index: 2;
        width: 35%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /* 셀렉트 박스 스타일 */
    .info-content select {
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
    }
}