.share-text{
    color: white;
}
.course-detail-title{
    font-size: var(--font-extra-large);
    font-weight: var(--font-weight-semiBold);
    margin: 0;
    color: white;
    white-space: pre-wrap;
}
.course-detail-share{
    display: flex;
    align-items: center;
    justify-content: right;
    cursor: pointer;
}

.recruitment-period, .education-period{
    color: #b2b2b2;
}
.disabled{
    background-color: #D9D9D9 !important;
}
.register-section-container{
    width: 80vw;
    margin: 0 auto;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.deadline{
    margin: 0;
}

.course-info{
    margin-top: 5px;
}
/* 노트북 & PC */
@media all and (width>1023px){  
    
    .disabled-line{
        border: 2px solid #D9D9D9 !important;
    }
    .register-btn{
        font-size: var(--font-medium);
    }
    .deadline{
        color: var(--color-lightFontGray);
        font-size: var(--font-small);
    }
    .ddayTimer{
        color: #f76e6e;
        font-size: var(--font-medium);
    }
    .course-detail-date p{
        margin: 6px 0px 0px;        
        font-size: 1rem;
    }
    
    .register-btn{
        color: white;
        height: 60px;
        border: none;
        padding: 10px 20px;
        margin: 0 auto;
        cursor: pointer;
        background-color: #326aff;
        font-weight: var(--font-weight-medium);
        border-radius: 10px;
        min-width: 200px;

        -ms-user-select: none; 
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
    .register-nav-right{
        display: flex;
        align-items: end;
        justify-content: space-between;
        gap: 10px;
    }
    .register-btn-share-container{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .course-detail-date img{
        width: 1rem !important;
        height: 1rem;
    }
    .course-detail-date{    
        display: flex;
        align-items: center;
        gap: 10px;
    }
    

}
/* 태블릿 가로, 세로 */
@media all and (min-width:768px) and (max-width:1023px){

    .course-detail-title{
        font-size: var(--font-large);
    }
    .register-btn{
        font-size: var(--font-regular);
    }

    .course-detail-date p{
        margin: 6px 0px 0px;        
        font-size: 0.9rem;
    }
    .deadline{
        color: var(--color-lightFontGray);
        font-size: var(--font-small);
    }
    .ddayTimer{
        color: #f76e6e;
        font-size: var(--font-regular);
    }
    .course-detail-date p{
        margin: 6px 0px 0px;        
        font-size: 0.85rem;
    }
    .register-btn{
        color: white;
        height: 60px;
        border: none;
        padding: 10px 10px;
        margin: 0 auto;
        cursor: pointer;
        background-color: #326aff;
        font-weight: var(--font-weight-medium);
        border-radius: 10px;
        min-width: 100px;
    }
    .register-nav-right{
        display: flex;
        align-items: end;
        justify-content: space-between;
        gap: 10px;
    }
    .register-btn-share-container{
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .course-detail-date img{
        width: 1rem !important;
        height: 1rem;
    }
    .course-detail-date{    
        display: flex;
        align-items:center;
        gap: 10px;
    }
    

}

/* 모바일 가로, 세로 */
@media all and (width<768px){

    .register-nav-right {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .deadline {
        order: -1; /* deadline을 맨 위로 이동 */
        width: 100%;
        color: var(--color-lightFontGray);
        font-size: var(--font-ultra-super-extra-small);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        line-height: 1.2;
        margin-bottom: 5px;
    }

    .register-btn-share-container {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    /* 나머지 스타일은 유지 */
    .course-detail-title{
        font-size: var(--font-regular);
        padding-right: 10px;
    }
    
    .ddayTimer{
        color: #f76e6e;
        font-size: var(--font-super-extra-small);
        display: block;
        margin-top: 2px;
    }
    
    .register-btn{
        color: white;
        border: none;
        padding: 7px 5px;
        margin: 0 auto;
        cursor: pointer;
        background-color: #326aff;
        font-weight: var(--font-weight-medium);
        font-size: var(--font-super-extra-small);
        border-radius: 5px;
        height:30px;
        min-width: 60px;
    }
    
    .course-detail-date p{
        margin: 6px 0px 0px;        
        font-size: 0.55rem;
    }
    .course-detail-date img{
        width: 0.7rem !important;
        height: 0.7rem;
    }
    .course-detail-date{    
        display: flex;
        align-items:center;
        gap: 5px;
    }
    
}

/* 푸터 영역에 도달했을 때의 스타일 */
@media all and (width>1023px) {
    .register-nav.at-footer {
        position: absolute;
        bottom: 300px; /* PC 푸터 높이 */
    }
}

@media all and (min-width:768px) and (max-width:1023px) {
    .register-nav.at-footer {
        position: absolute;
        bottom: 300px; /* 태블릿 푸터 높이 */
    }
}

@media all and (width<768px) {
    .register-nav.at-footer {
        position: absolute;
        bottom: 330px; /* 모바일 푸터 높이 */
    }
}

.register-section {
    width: 100%;
}
