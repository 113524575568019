/* 기본 컨테이너 스타일 */
.course-page-container {
  width: 100%;
  margin: 0 auto 10vh;
  box-sizing: border-box;
}

/* 과정 목록 컨테이너 */
.course-list {
  padding: 2rem 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2%;
}

/* 더보기 버튼 컨테이너 */
.show-more-btn-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 7rem;
}

/* 더보기 버튼 스타일 */
.show-more-btn {
  box-sizing: border-box;
  background-color: cornflowerblue;
  color: whitesmoke;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: var(--font-weight-semiBold);
  min-width: 120px;
  text-align: center;

  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.show-more-btn:hover {
  transform: translateY(-2px);
}

/* 과정 없음 메시지 */
.no-course-message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20vh 2rem;
  font-size: var(--font-regular);
  color: #666;
}

/* 반응형 스타일 */
/* 노트북 & PC (1024px 이상) */
@media all and (min-width: 1024px) {
  .course-list {
    gap: 2%;
    padding: 2rem 10vw 5rem;
  }
  .show-more-btn {
    padding: 12px 24px;
    font-size: var(--font-regular);
  }
}

/* 태블릿 (768px - 1023px) */
@media all and (min-width: 768px) and (max-width: 1023px) {
  .course-list {
    gap: 3.5%;
    padding: 1.5rem 10vw 3rem;
  }
  .show-more-btn {
    padding: 11px 20px;
    font-size: var(--font-small);
  }
}

/* 모바일 (767px 이하) */
@media all and (max-width: 767px) {
  .course-list {
    gap: 4%;
    padding: 1rem 10vw 2rem;
  }
  .show-more-btn {
    padding: 9px 16px;
    font-size: var(--font-extra-small);
  }
  .no-course-message {
    font-size: var(--font-small);
    padding: 1rem;
  }
}
