p, span {
    color: var(--color-black);
}

.detailcategory-container {
    /* border: 2px solid red; */
    /* width: 80vw; */
    margin: 0 auto;
    margin-left: 0;
}

.detailcategory-list-container {
    /* width: 80vw; */
    display: flex;
    align-items: center;
    column-gap: 15px;
    row-gap: 10px;
    flex-wrap: wrap; /* 줄바꿈 허용 */
}

.w-detailcategory-list-container {
    width: 80vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    column-gap: 15px;
    row-gap: 10px;
    flex-wrap: wrap; /* 줄바꿈 허용 */
}

.detailcategory-container span {
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    border-radius: 8px;
    background-color: #ebebeb;
    padding: 9px;
    box-sizing: border-box;
    cursor: pointer;
    white-space: nowrap; /* 세로로 글자가 쓰이지 않도록 설정 */
    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.detailcategory-container span:hover {
    background-color: #ccc;
}

/* 클릭된 상태 */
.active-category {
    background-color: var(--color-black) !important;
    color: white;
    
}


/* 첫 퍼블리싱을 웹을 기준으로 했지만 width>1023을 적용시키려면(소수점을 적용시키려면) 바뀐 부분은 웹 전용으로 한번 더 써줘야함 */

@media all and (width>1023px) {

    .detailcategory-container span {
        border-radius: 8px;
        font-size: var(--font-regular);
        padding: 9px;
    }
}

@media all and (min-width:768px) and (max-width:1023px) {

    .detailcategory-container span {
        border-radius: 7px;
        font-size: var(--font-small);
        padding: 8px;
    }
}

@media all and (width<768px) {

    .detailcategory-container span {
        border-radius: 6px;
        font-size: var(--font-extra-small);
        padding: 7px;
    }
}