.loading-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
/* 로딩 스피너 */
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .loading-spinner {
    border: 8px solid rgba(0, 0, 0, 0.2);
    border-top: 8px solid #3498db; /* 스피너의 메인 색상 */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }