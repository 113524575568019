.error-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8d7da;
    color: #721c24;
    text-align: center;
    background-color: white;
}

.error-page-content {
  /* background-color: #fff; */
  padding: 30px;
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  /* max-width: 500px; */
  width: 100%;
}

.error-page-error-name {
  font-weight: var(--font-weight-bold);
  margin-top: 16px;
  margin-bottom: 0;
}

.error-page-title {
  font-weight: var(--font-weight-semiBold);
  margin-top: 16px;
  margin-bottom: 24px;
}

.error-page-button {
  border: none;
  display: inline-block;
  margin-top: 20px;
  background-color: var(--color-black);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.error-page-button:hover {
  /* background-color: #0046ff; */
  background-color: #464646;
}

.error-page-button:focus {
  outline: none;
}

  
@media all and (min-width: 1024px) {  
  .error-page-content {
    margin-top: 90px;
  }
  
  .error-page-error-name {
    font-size: 40px;
  }
  
  .error-page-title {
    font-size: 32px;
  }

  .error-page-message {
    font-size: var(--font-medium);
  }
  
  .error-page-button {
    padding: 12px 20px;
    font-size: var(--font-regular);
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .error-page-content {
    margin-top: 50px;
  }

  .error-page-error-name {
    font-size: 36px;
  }
  
  .error-page-title {
    font-size: 28px;
  }

  .error-page-message {
    font-size: var(--font-regular);
  }
  
  .error-page-button {
    padding: 11px 18px;
    font-size: var(--font-small);
  }
}

@media all and (max-width: 767px) {
  .error-page-content {
    margin-top: 50px;
  }

  .error-page-error-name {
    font-size: 32px;
  }
  
  .error-page-title {
    font-size: 24px;
  }

  .error-page-message {
    font-size: var(--font-small);
  }
  
  .error-page-button {
    padding: 10px 16px;
    font-size: var(--font-extra-small);
  }
}