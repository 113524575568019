.register-button {
    margin-top: 15vh;
}
.cancel-btn{
    border: 2px solid var(--color-blue);
    color: var(--color-blue);
    background-color: white;
    cursor: pointer;
    
    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.next-btn{
    border: none;
    color: white;
    background-color: var(--color-blue);
    cursor: pointer;

    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.disabled{
    background-color: #D9D9D9;
}

/* 노트북 & PC */
@media all and (width>1023px){

    .register-button {
        text-align: center;
    }
    .register-button button{
        font-size: var(--font-large);
        height: 50px;
        width: 13vw;
        margin-left: 0.5vw;
        margin-right: 0.5vw;
        border-radius: 10px;
    }
}
/* 태블릿 가로, 세로 */
@media all and (min-width:768px) and (max-width:1023px){
    .register-button {
        text-align: center;
    }
    .register-button button{
        font-size: var(--font-medium);
        height: 45px;
        width: 13vw;
        margin-left: 0.5vw;
        margin-right: 0.5vw;
        border-radius: 10px;
    }
}
/* 모바일 가로, 세로 */
@media all and (width<768px){
    .register-button {
        display: flex;
        justify-content: space-between;
    }
    .register-button button{
        font-size: var(--font-small);
        height: 40px;
        width: 48%;
        border-radius: 10px;
    }
}
