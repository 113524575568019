p, span {
    color: var(--color-black);
}

.contents-container {
    width: 80vw;
    margin: 0 auto;
    margin-bottom: 50px;
}

.contents-list-container {
    width: 80vw;
}

.contents-container-hr {
    border: 1px solid #eeeeef;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 0px;
}

.contents-list {
    display: flex;
    padding: 20px;
    /* border-bottom: 1px solid #eeeeef; */
    height: 200px;
    cursor: pointer;
    border-radius: 8px;
}

.contents-list:hover {
    /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08); */
}

.contents-list-hr {
    margin-top: 20px;
    margin-bottom: 20px;
}

.v-contents-list-hr {
    margin-top: 0;
}


.story-contents-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center; /* 세로 중앙 정렬 */
    height: 100%; /* 부모 높이에 맞게 설정 */
    margin-bottom: 120px;
}
  
.loading {
    flex: 1; /* 부모의 남은 공간을 차지하게 설정 */
}  

.contents-text-container {
    width: 100%;
    height: 200px;
}

.contents-list-img-container {

}


.contents-list img {
    width: 300px;
    height: 200px;
    border-radius: 5px;
    margin-right: 3vw;
    object-fit: cover;
}

.contents-category {
    font-size: var(--font-large);
    font-weight: 600;
    color: var(--color-blue);
}

.contents-title {
    font-size: var(--font-large);
    font-weight: 600;

    /* 1줄로 글자 수 제한하기 */
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.contents-introduce {
    font-size: var(--font-medium);
    line-height: 1.5;
    height: 80px;

    /* 3줄로 글자 수 제한하기 */
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.contents-introduce p {
    margin-top: 0;
}

.contents-regi-date {
    font-size: var(--font-regular);
    color: #a5a5a5;
    margin-bottom: 0;
    margin-top: 0;
}

/* 여기서부턴 VideoContents꺼 */

.v-contents-list-container {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(21vw, 1fr)); */
    grid-template-columns: repeat(3, 1fr); /* 한 줄에 3개씩 */
    column-gap: 4vw;
}

.v-contents-list {
    /* width: 21vw; */
    width: 80vw;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
}

.v-contents-list:hover {
    /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08); */
}

.v-contents-list img {
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    object-fit: cover;
}

.v-contents-list-container .v-contents-list {
    display: table-cell; /* 나란히 몇 개 배열하는 코드의 핵심! */
    vertical-align: middle;  
}

.v-contents-title-container {
    margin-top: 5px;
}

.v-contents-category {
    font-size: var(--font-large);
    font-weight: 600;
    color: var(--color-blue);
}

.v-contents-title {
    font-size: var(--font-large);
    font-weight: 600;
    margin-top: 5px;
    cursor: pointer;

    /* 1줄로 글자 수 제한하기 */
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.v-contents-introduce {
    font-size: var(--font-regular);
    line-height: 1.5;
    margin-top: 10px;
    margin-bottom: 15px;
    height: 45px;

    /* 2줄로 글자 수 제한하기 */
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.v-contents-introduce p {
    margin-top: 0;
}

.v-contents-regi-date {
    font-size: var(--font-small);
    color: #a5a5a5;
    margin-bottom: 0;
    margin-top: 0;
}

.see-more-btn-container {
    margin-top: 40px;
    text-align: center;
}

.see-more-btn {
    /* box-sizing: border-box;
    background-color: #6E6F72;
    padding: 12px;
    font-size: var(--font-regular);
    font-weight: var(--font-weight-semiBold);
    color: whitesmoke;
    border-radius: 8px;
    cursor: pointer; */

    box-sizing: border-box;
  background-color: cornflowerblue;
  color: whitesmoke;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: var(--font-weight-semiBold);
  min-width: 120px;
  text-align: center;

  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}



.no-contents-message {
    color: #666;
    text-align: center;
}