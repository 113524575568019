.facility-zone {
  background-color: #fff;
  padding: 10vh 10vw;
  position: relative;
  padding-bottom: 100px;
  z-index: 1;
}
.facility-zone::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.facility-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.facility-title {
  font-size: 44px;
  font-weight: var(--font-weight-semiBold);
  align-items: center;
  display: flex;
  color: #fff;
}
.main-facility {
  margin-right: 20px;
}

@media all and (width>1023px) {
  .mobile-facility-header {
    display: none;
  }
  .facility-text,
  .facility-subtext {
    color: #fff;
    font-size: var(--font-extra-large);
    font-weight: var(--font-weight-semiBold);
    margin-bottom: 20px;
  }
}

@media all and (min-width: 768px) and (max-width: 1023px) {
  .facility-header {
    display: none;
  }
  .mobile-facility-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .mobile-facility-title {
    font-size: 36px;
    color: #fff;
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    display: flex;
  }
  .facility-text,
  .facility-subtext{
    color: #fff;
    font-size: var(--font-large);
    font-weight: var(--font-weight-medium);
    margin-bottom: 20px;
    text-align: center;
  }
}

@media all and (width<768px) {
  .facility-header {
    display: none;
  }
  .mobile-facility-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .mobile-facility-title {
    font-size: var(--font-large);
    color: #fff;
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    display: flex;
  }
  .facility-text,
  .facility-subtext{
    color: #fff;
    font-size: var(--font-regular);
    font-weight: var(--font-weight-regular);
    margin-bottom: 20px;
    text-align: center;
  }
}
