/* 첫 퍼블리싱을 웹을 기준으로 했지만 width>1023을 적용시키려면(소수점을 적용시키려면) 바뀐 부분은 웹 전용으로 한번 더 써줘야함 */

@media all and (width>1023px) {

    .firstzone-contents-container {
        margin-bottom: 120px;
    }

    .notice-list-title {
        font-size: var(--font-medium);
        padding: 20px 25px;
    }
    
    .faq-list-title {
        font-size: var(--font-medium);
        padding: 20px 25px;
    }
    
    .notice-content {
        font-size: var(--font-medium);
        padding: 20px;
        line-height: 160%;
    }
    
    .faq-content {
        font-size: var(--font-regular);
        padding: 20px 25px;
    }

    .notice-content img {
        /* max-width: 770px; */
    }

    .faq-content img {
        /* max-width: 360px; */
    }

    .building-location-map {
        
    }
    
    .building-location-introduce {
        /* max-width: 328px; */
    }


    .firstzone-contents-list-container img {
        /* max-width: 820px; */
    }


    .page-number {
        font-size: var(--font-medium);
    }

    .page-number.active {
        padding-bottom: 0;
    }
    
    .page-button {
        font-size: var(--font-small);
    }

    .page-button-block {
        font-size: 23px !important;
        margin: 0 15px;
    }


    .firstmap-img-intro-title {
        font-size: 22px;
    }
    
    .firstmap-find-intro-title {
        font-size: 22px;
    }


    .notice-list-content {
        font-size: var(--font-medium);
        padding: 20px 25px;
    }
    
    .notice-list-content p {
        font-size: var(--font-regular);
    }
    
    .notice-list-content span {
        font-size: var(--font-small);
        color: #a5a5a5;
    }


    .notice-list-toggle-button span {
        font-size: 45px;
    }

    .faq-list-toggle-button span {
        font-size: 45px;
    }

    .notice-list-toggle-button {
        margin-bottom: 10px;
    }

    .faq-list-toggle-button {
        margin-bottom: 10px;
    }


    .question-total-container {
        padding: 20px;
    }

    .question-title-introduce {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: var(--font-medium);
    }

    .question-user-container p {
        font-size: var(--font-regular);
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .question-user-container input {
        font-size: var(--font-regular);
        border-radius: 5px;
    }

    .question-btn {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: var(--font-regular);
        padding: 15px;
        border-radius: 5px;
    }

    .question-content-container textarea {
        font-size: var(--font-regular);
        border-radius: 5px;
    }
}

@media all and (min-width:768px) and (max-width:1023px) {

    .firstzone-contents-container {
        margin-bottom: 80px;
    }
  
    .notice-list-title {
        font-size: var(--font-regular);
        padding: 15px 20px;
    }
    
    .faq-list-title {
        font-size: var(--font-regular);
        padding: 15px 20px;
    }
    
    .notice-content {
        font-size: var(--font-regular);
        padding: 20px;
        line-height: 160%;
    }
    
    .faq-content {
        font-size: var(--font-small);
        padding: 15px 20px;
    }
    
    .notice-content img {
        /* max-width: 560px; */
    }

    .faq-content img {
        /* max-width: 254px; */
    }


    .building-location-map {
        
    }
    
    .building-location-introduce {
        /* max-width: 246px; */
    }


    .firstzone-contents-list-container img {
        /* max-width: 615px; */
    }


    .page-number {
        font-size: var(--font-regular);
    }

    .page-number.active {
        padding-bottom: 0;
    }
    
    .page-button {
        font-size: var(--font-extra-small);
    }

    .page-button-block {
        font-size: 20px !important;
        margin: 0 12px;
    }


    .firstmap-img-intro-title {
        font-size: var(--font-large);
    }
    
    .firstmap-find-intro-title {
        font-size: var(--font-large);
    }


    .notice-list-content {
        font-size: var(--font-regular);
        padding: 15px 20px;
    }

    .notice-list-content p {
        font-size: var(--font-small);
    }
    
    .notice-list-content span {
        font-size: var(--font-extra-small);
        color: #a5a5a5;
    }


    .notice-list-toggle-button span {
        font-size: 40px;
    }

    .faq-list-toggle-button span {
        font-size: 40px;
    }

    .notice-list-toggle-button {
        margin-bottom: 7px;
    }

    .faq-list-toggle-button {
        margin-bottom: 7px;
    }


    .question-total-container {
        padding: 15px;
    }

    .question-title-introduce {
        margin-bottom: 10px;
        font-size: var(--font-regular);
    }

    .question-user-container p {
        font-size: var(--font-small);
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .question-user-container input {
        font-size: var(--font-small);
        border-radius: 5px;
    }

    .question-btn {
        margin-top: 10px;
        font-size: var(--font-small);
        padding: 10px;
        border-radius: 5px;
    }

    .question-content-container textarea {
        font-size: var(--font-small);
        border-radius: 5px;
    }
}

@media all and (width<768px) {

    .firstzone-contents-container {
        margin-bottom: 50px;
    }

    .notice-list-title {
        font-size: var(--font-small);
        padding: 10px 15px;
    }
    
    .faq-list-title {
        font-size: var(--font-small);
        padding: 10px 15px;
    }
    
    .notice-content {
        font-size: var(--font-small);
        padding: 20px;
        line-height: 160%;
    }
    
    .faq-content {
        font-size: var(--font-extra-small);
        padding: 10px 15px;
    }

    .notice-content img {
        /* max-width: 205px; */
    }

    .faq-content img {
        /* max-width: 205px; */
    }


    .building-location-map {
        
    }
    
    .building-location-introduce {
        /* max-width: 256px; */
    }


    .firstzone-contents-list-container img {
        /* max-width: 256px; */
    }


    .page-number {
        font-size: var(--font-small);
    }

    .page-number.active {
        padding-bottom: 1px;
    }
    
    .page-button {
        font-size: var(--font-super-extra-small);
    }

    .page-button-block {
        margin: 0 10px;
    }


    .firstmap-img-intro-title {
        font-size: var(--font-regular);
    }
    
    .firstmap-find-intro-title {
        font-size: var(--font-regular);
    }


    .notice-list-content {
        font-size: var(--font-small);
        padding: 10px 15px;
    }

    .notice-list-content p {
        font-size: var(--font-extra-small);
    }
    
    .notice-list-content span {
        font-size: var(--font-super-extra-small);
        color: #a5a5a5;
    }

    
    .notice-list-toggle-button span {
        font-size: 35px;
    }

    .faq-list-toggle-button span {
        font-size: 35px;
    }

    .notice-list-toggle-button {
        margin-bottom: 6px;
    }

    .faq-list-toggle-button {
        margin-bottom: 6px;
    }

    .faq-content-total-container {
        display: block;
    }

    .faq-list-total-container {
        width: 100%;
    }


    .question-total-container {
        margin-top: 25px;
    }

    .question-total-container {
        width: auto;
    }

    .question-total-container {
        padding: 15px;
    }

    .question-title-introduce {
        margin-bottom: 10px;
        font-size: var(--font-small);
    }

    .question-user-container p {
        font-size: var(--font-extra-small);
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .question-user-container input {
        font-size: var(--font-extra-small);
        border-radius: 5px;
    }

    .question-btn {
        margin-top: 10px;
        font-size: var(--font-extra-small);
        padding: 10px;
        border-radius: 5px;
    }

    .question-content-container textarea {
        font-size: var(--font-extra-small);
        border-radius: 5px;
        height: 20vw;
    }


    .building-location-container {
        /* width: 80vw; */
        /* text-align: center;
        display: block;
        margin: 0 auto; */
    }
}