@media all and (min-width: 768px) and (max-width: 1023px) {
    .mobile-story-card {
      width: 50vw;
      height: 400px;
      border-radius: 10px;
      /* margin-left: 5vw; */
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  
    .mobile-story-img-zone {
      width: 100%;
      height: 100%;
    }
  
    .mobile-story-img-zone img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .post-title{
        text-align: center;
        margin-bottom: 20px;
    }
    .mobile-add-story{
        text-align: center;
        margin: 50px 0;
        font-size: var(--font-medium);
        font-weight: var(--font-weight-semiBold);
    }
    .custom-swiper-prev,
.custom-swiper-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #333;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8); 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prev-icon, .next-icon{
  width: 30%;
  height: 46%;
}
.custom-swiper-prev {
  left: -10px; /* 왼쪽 끝에 배치 */
}

.custom-swiper-next {
  right: -10px; /* 오른쪽 끝에 배치 */
}

.custom-swiper-prev:active,
.custom-swiper-next:active {
  background-color: rgba(107, 100, 122, 0.8);
  color: #fff;
}
  }
  @media all and (width<768px){
    .mobile-story-card {
        width: 70vw;
        height: 300px;
        border-radius: 10px;
        /* margin-left: 5vw; */
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }
      .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    
      .mobile-story-img-zone {
        width: 100%;
        height: 100%;
      }
    
      .mobile-story-img-zone img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .post-title{
        font-size: var(--font-large);
          text-align: center;
          margin-bottom: 20px;
      }
      .mobile-add-story{
          text-align: center;
          margin: 50px 0;
          font-size: var(--font-regular);
          font-weight: var(--font-weight-semiBold);
      }
      .custom-swiper-prev,
.custom-swiper-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #333;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8); 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prev-icon, .next-icon{
  width: 30%;
  height: 46%;
}
.custom-swiper-prev {
  left: -10px; /* 왼쪽 끝에 배치 */
}

.custom-swiper-next {
  right: -10px; /* 오른쪽 끝에 배치 */
}

.custom-swiper-prev:active,
.custom-swiper-next:active {
  background-color: rgba(107, 100, 122, 0.8);
  color: #fff;
}
  }