/* 기본 레이아웃 */
.info-container {
    width: 80vw;
    margin: 10vh auto 20vh;
    max-width: 1280px;
    z-index: 1;
}

/* 제목 영역 */
.info-title {
    display: flex;
    justify-content: space-between;
    align-items: end;
    border-bottom: 2px solid var(--color-black);
}

/* 필수 입력 표시 */
.star {
    color: #F62525;
    margin-left: 0.5vw;
}

/* 에러 메시지 */
.error-message {
    font-size: var(--font-small);
    text-align: center;
    color: #F62525;
    height: 17px;
}
/* 사진 에러 메시지 */
.error-msg {
    font-size: var(--font-small);
    text-align: center;
    color: #F62525;
    height: 17px;
}
/* 반응형 스타일 - PC (1024px 이상) */
@media all and (width > 1023px) {
    .info-title-text {
        font-weight: var(--font-weight-semiBold);
        font-size: var(--font-large);
    }
    
    .info-required {
        font-size: var(--font-regular);
    }
}

/* 반응형 스타일 - 태블릿 (768px - 1023px) */
@media all and (min-width: 768px) and (max-width: 1023px) {
    .info-title-text {
        font-weight: var(--font-weight-semiBold);
        font-size: var(--font-medium);
    }
    
    .info-required {
        font-size: var(--font-regular);
    }
}

/* 반응형 스타일 - 모바일 (768px 미만) */
@media all and (width < 768px) {
    .info-title-text {
        font-weight: var(--font-weight-semiBold);
        font-size: var(--font-regular);
    }
    
    .info-required {
        font-size: var(--font-extra-small);
    }
}

