html,
body,
#root {
  min-width: 320px;
}

body {
  margin: 0;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, system-ui,
    Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
}


button{
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, system-ui,
    Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
}

@font-face {
  font-family: 'Pretendard';
  src: url('/src/font/PRETENDARD-EXTRALIGHT.OTF') format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/src/font/PRETENDARD-LIGHT.OTF') format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/src/font/PRETENDARD-THIN.OTF') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/src/font/PRETENDARD-REGULAR.OTF') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/src/font/PRETENDARD-MEDIUM.OTF') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/src/font/PRETENDARD-SEMIBOLD.OTF') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/src/font/PRETENDARD-BOLD.OTF') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('/src/font/PRETENDARD-EXTRABOLD.OTF') format('opentype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard';
  src: url('/src/font/PRETENDARD-BLACK.OTF') format('opentype');
  font-weight: 900;
  font-style: normal;
}



code {
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

:root {
  --color-black: #2c2c2c;
  --color-sky-blue: #2EB4FF;
  --color-blue: #0046ff;
  --color-lightFontGray: #ccc7c0;
  --color-lightLineGray: #ccc7c0;
  --font-ultra-super-extra-small: 8px;
  --font-super-extra-small: 10px;
  --font-extra-small: 12px;
  --font-small: 14px;
  --font-regular: 16px;
  --font-medium: 18px;
  --font-large: 20px;
  --font-extra-large: 24px;
  --font-super-extra-large: 28px;
  --font-weight-light: 100;
  --font-weight-regular: 300;
  --font-weight-medium: 500;
  --font-weight-semiBold: 700;
  --font-weight-bold: 1000;
}
