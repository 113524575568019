.firstzone-contents-container {
    width: 80vw;
    margin: 0 auto;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    margin-bottom: 120px;
}

.first-category-bottom-hr {
    margin-top: -20px;
    margin-bottom: 20px;
    border: 1px solid #eeeeef;
    height: 0;
}

.firstzone-contents-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.firstzone-contents-list-container p {
    font-size: var(--font-super-extra-large);
    font-weight: var(--font-weight-semiBold);
    margin-top: 0px;
    margin-bottom: 25px;
}

.firstzone-contents-list-container img {
    /* max-width: 820px; */
    width: 100%;
    margin-bottom: 50px;
}

.firstzone-contents-ourclient {
    margin-bottom: 0 !important;
}

.firstmap-total-contents-list-container {
    display: flex;
    flex-direction: column; /* 세로 정렬을 유지하면서 */
    align-items: center; /* 가로 방향 중앙 정렬 */
    text-align: center;
}

.firstmap-contents-list-container {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden; /* 이미지 넘친거 숨김 */
    width: 100%;
}

.slider {
    display: flex;
    width: 100%;
    transition: transform 0.5s ease-in-out; /* 애니메이션 전환 효과 */
    align-items: center;
}

.firstmap-contents-img {
    width: 100%;
    /* object-fit: cover; */
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 2vw;
    cursor: pointer;
    padding: 1vw;
    z-index: 1;
}

.slider-button.left {
    left: 10px; /* 왼쪽 버튼 위치 */
}

.slider-button.right {
    right: 10px; /* 오른쪽 버튼 위치 */
}

.slider-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* 도트 네비게이션 */
.dots-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: lightgray;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.dot.active {
    background-color: #25A4FF; /* 활성화된 도트는 파란색 */
}

.building-location-container {
    /* width: 80vw; */
    /* text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; */
}

.firstmap-img-intro-title {
    font-size: 22px;
    font-weight: var(--font-weight-semiBold);
    margin-top: 0;
    margin-bottom: 25px;
}

.firstmap-find-intro-title {
    font-size: 22px;
    font-weight: var(--font-weight-semiBold);
    margin-top: 60px;
    margin-bottom: 25px;
}

.building-location-map {
    width: 80vw;
    height: 35vw;
}

.building-location-introduce {
    width: 100%;
}

.notice-container {
    /* width: 80vw; */
    margin: 0 auto;
}

.list-margin {
    margin-top: 40px;
}

.notice-list {
    width: 80vw;
    border-radius: 8px;
    /* background-color: #F0F2F5; */
    /* background-color: #E9E8E8; */
    /* border: 1px solid #a5a5a5; */
    margin-top: 10px;
}

.faq-container {
    width: 80vw;
}

.faq-content-total-container {
    width: 80vw;
    display: flex;
}

.faq-list-total-container {
    width: 55%;
    margin-right: 3%;
}

.question-total-container {
    width: 45%;
    height: 100%;
    margin-top: 10px;
    border: #8E8E93 solid 1px;
    border-radius: 10px;
    padding: 20px;
}

.question-title-introduce {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: var(--font-medium);
    font-weight: var(--font-weight-semiBold);
}

.question-total-container hr {
    margin-top: 0;
    height: 0;
    margin-bottom: 12px;
    border: 1px solid var(--color-lightLineGray);
}

.question-user-container {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.question-user-container p {
    font-size: var(--font-regular);
    width: 20%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
}

.question-content-container {
    margin-top: 15px;
}

.question-btn {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: var(--font-regular);
    text-align: center;
    color: white;
    background-color: #A2A2A2;
    border: none;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
}

.question-user-container input {
    font-size: var(--font-regular);
    width: 80%;
    border: var(--color-lightLineGray) solid 1px;
    border-radius: 5px;
    padding: 5px;
}

.question-content-container textarea {
    resize: none; /* textarea는 사용자가 크기 늘릴 수 있는데 그거 막기 */
    font-size: var(--font-regular);
    width: 100%;
    height: 15vw;
    border: var(--color-lightLineGray) solid 1px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
}


.faq-list {
    width: 100%;
    border-radius: 8px;
    /* background-color: #F0F2F5; */
    /* background-color: #E9E8E8; */
    /* border: 1px solid #a5a5a5; */
    margin-top: 10px;
}

.notice-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid var(--color-lightLineGray);
    cursor: pointer;
}

.notice-list-header:hover {
    background-color: #EEEEEE;
}

.faq-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #a5a5a5;
    cursor: pointer;
}

.faq-list-header:hover {
    background-color: #EEEEEE;
}

.notice-list-title {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-medium);
    flex-grow: 1;
    word-wrap: break-word; /* 긴 단어도 줄바꿈 */
    word-break: break-all; /* 필요한 경우 단어 중간에서도 줄바꿈 */
    white-space: normal; /* 자동 줄바꿈 활성화 */
}

.faq-list-title {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-medium);
    flex-grow: 1; /* 타이틀 영역을 최대한 차지하도록 설정 */
    word-wrap: break-word; /* 긴 단어도 줄바꿈 */
    word-break: break-all; /* 필요한 경우 단어 중간에서도 줄바꿈 */
    white-space: normal; /* 자동 줄바꿈 활성화 */
}

.open-title { /* 클릭된 공지사항의 제목 */
    
}

.notice-list-button {
    margin-right: 25px;
    float: right;
    width: 22px;
    transition: transform 0.3s ease;
}

.faq-list-button {
    margin-right: 25px;
    float: right;
    width: 22px;
    transition: transform 0.3s ease;
}

.notice-list-content {
    font-size: var(--font-medium);
    background-color: #f9f9f9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.notice-list-content img {
    /* max-width: 770px; */
}

.notice-list-content p {
    font-size: var(--font-regular);
}

.notice-list-content span {
    font-size: var(--font-small);
    color: #a5a5a5;
}

.faq-content {
    font-size: var(--font-regular);
    background-color: #f9f9f9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.faq-content img {
    /* max-width: 360px; */
}


/* 페이지네이션 */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.page-number {
    border: none;
    color: var(--color-black);
    padding: 2px 5px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    font-size: var(--font-medium); /* 숫자 크기 설정 */
    background-color: transparent;
}

.page-number.active {
    color: var(--color-black);
    font-weight: var(--font-weight-semiBold);
    border-bottom: 1px solid black;
    border-radius: 0;
    padding-bottom: 0;
    background-color: transparent;
}

.page-button {
    border: none;
    color: #333;
    padding: 0;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 4px;
    background-color: transparent;
}

.page-button:hover {
    background-color: transparent; /* 배경색 없음 */
}

.page-button:disabled {
    cursor: default;
    opacity: 0.5;
    color: #999;
    background-color: transparent;
}

.page-button.active {
    color: var(--color-black);
    font-weight: var(--font-weight-semiBold);
    font-size: var(--font-medium);
    background-color: transparent;
}

.page-button:first-child, 
.page-button:last-child {
    font-size: var(--font-medium); /* 화살표 크기 조정 */
    background-color: transparent; /* 배경색 없음 */
}

.page-button.active:hover {
    background-color: transparent; /* 선택된 버튼의 hover 배경색 없음 */
}


/* 여긴 공지사항 토글버튼들 */
.notice-list-toggle-button {
    margin-right: 25px;
    margin-bottom: 10px;
    cursor: pointer;

    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.notice-list-toggle-button span {
    display: inline-block;
    transition: transform 0.2s;
    font-size: 45px;
}

.notice-list-toggle-open {
    transform :rotate(45deg);
}

/* 여긴 faq 토글버튼들 */
.faq-list-toggle-button {
    margin-right: 25px;
    margin-bottom: 10px;
    cursor: pointer;

    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.faq-list-toggle-button span {
    display: inline-block;
    transition: transform 0.2s;
    font-size: 45px;
}

.faq-list-toggle-open {
    transform :rotate(45deg);
}

/* swiper */
.swiper-button-prev,
.swiper-button-next {
  background-color: #fff;
  padding: 2px 10px;
  border-radius: 50%;
  color: black !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}