p, span {
    color: var(--color-black);
}

.contents-detail-container {
    width: 80vw;
    min-height: 100vh;
    margin: 0 auto;
    margin-bottom: 120px;
}

.contents-detail-list-container {
    width: 80vw;
}

.contents-detail-menu {
    display: inline-block; /* 이게 있어야 margin 줄 수 있음 */
    font-size: 22px;
    font-weight: var(--font-weight-semiBold);
    /* border-bottom: 2px solid var(--color-black);
    padding-bottom: 2px; */
    margin-top: 60px;
    margin-bottom: 30px;
}

.contents-detail-category {
    font-size: var(--font-large);
    font-weight: var(--font-weight-semiBold);
    color: var(--color-blue);
    margin-right: 1vw;
}

.contents-detail-title {
    font-size: var(--font-large);
    font-weight: var(--font-weight-semiBold);
    width: 60vw;

    word-wrap: break-word; /* 긴 단어도 줄바꿈 */
    word-break: break-all; /* 필요한 경우 단어 중간에서도 줄바꿈 */
    white-space: normal; /* 자동 줄바꿈 활성화 */
}

.contents-detail-date {
    font-size: var(--font-regular);
    color: #a5a5a5;
}

.contents-detail-list-container hr {
    border: 1px solid #eeeeef;
    margin-top: 15px;
    margin-bottom: 25px;
}

.contents-detail-media {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-medium);
}

.toastui-editor-contents p,
    .toastui-editor-contents ul,
    .toastui-editor-contents ol,
    .toastui-editor-contents li,
    .toastui-editor-contents blockquote,
    .toastui-editor-contents pre,
    .toastui-editor-contents code,
    .toastui-editor-contents table,
    .toastui-editor-contents th,
    .toastui-editor-contents td {

        color: var(--color-black);
        font-family: 'Pretendard';
        font-size: var(--font-medium);
    }

.toastui-editor-contents th {
    font-weight: var(--font-weight-medium) !important;
}


/* 유튜브 영상 플레이어 */
.video-player-container {
    text-align: center;
}

.contents-detail-media iframe {
    /* width: 80%; */
    height: 600px;
    border: none;
    margin-bottom: 10px;
    border-radius: 8px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}


/* 첫 퍼블리싱을 웹을 기준으로 했지만 width>1023을 적용시키려면(소수점을 적용시키려면) 바뀐 부분은 웹 전용으로 한번 더 써줘야함 */

@media all and (width>1023px) {

    .contents-detail-container {
        margin-bottom: 120px;
    }

    .contents-detail-menu {
        font-size: 22px;
    }

    .contents-detail-category {
        font-size: var(--font-large);
    }

    .contents-detail-title {
        font-size: var(--font-large);
    }

    .contents-detail-date {
        font-size: var(--font-regular);
    }

    .contents-detail-media {
        font-size: var(--font-medium);
    }

    .toastui-editor-contents p,
    .toastui-editor-contents ul,
    .toastui-editor-contents ol,
    .toastui-editor-contents li,
    .toastui-editor-contents blockquote,
    .toastui-editor-contents pre,
    .toastui-editor-contents code,
    .toastui-editor-contents table,
    .toastui-editor-contents th,
    .toastui-editor-contents td {

        font-size: var(--font-medium);
    }


    .contents-detail-media iframe {
        /* width: 80%; */
        height: 600px;
        margin-bottom: 10px;
    }
}

@media all and (min-width:768px) and (max-width:1023px) {

    .contents-detail-container {
        margin-bottom: 80px;
    }

    .contents-detail-menu {
        font-size: var(--font-large);
    }

    .contents-detail-category {
        font-size: var(--font-medium);
    }

    .contents-detail-title {
        font-size: var(--font-medium);
    }

    .contents-detail-date {
        font-size: var(--font-small);
    }

    .contents-detail-media {
        font-size: var(--font-regular);
    }

    .toastui-editor-contents p,
    .toastui-editor-contents ul,
    .toastui-editor-contents ol,
    .toastui-editor-contents li,
    .toastui-editor-contents blockquote,
    .toastui-editor-contents pre,
    .toastui-editor-contents code,
    .toastui-editor-contents table,
    .toastui-editor-contents th,
    .toastui-editor-contents td {

        font-size: var(--font-regular);
    }


    .contents-detail-media iframe {
        /* width: 80%; */
        height: 500px;
        margin-bottom: 5px;
    }
}

@media all and (width<768px) {

    .contents-detail-container {
        margin-bottom: 50px;
    }

    .contents-detail-menu {
        font-size: var(--font-medium);
    }

    .contents-detail-category {
        font-size: var(--font-regular);
    }

    .contents-detail-title {
        font-size: var(--font-regular);
    }

    .contents-detail-date {
        font-size: var(--font-extra-small);
    }

    .contents-detail-media {
        font-size: var(--font-small);
    }

    .toastui-editor-contents p,
    .toastui-editor-contents ul,
    .toastui-editor-contents ol,
    .toastui-editor-contents li,
    .toastui-editor-contents blockquote,
    .toastui-editor-contents pre,
    .toastui-editor-contents code,
    .toastui-editor-contents table,
    .toastui-editor-contents th,
    .toastui-editor-contents td {

        font-size: var(--font-small);
    }


    .contents-detail-media iframe {
        /* width: 80%; */
        height: 400px;
        margin-bottom: 0;
    }
}