p, span {
    color: var(--color-black);
}

.video-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999; /* 모달 배경이 모달창보다 뒤에 있음 */
}

.video-detail-modal-container {
    position: fixed;
    top: 50%; /* 화면 중앙에 위치하도록 */
    left: 50%; /* 화면 중앙에 위치하도록 */
    transform: translate(-50%, -50%); /* 가운데 정렬을 위해 위치 조정 */
    width: 80vw;
    height: auto;
    background-color: white;
    z-index: 1000; /* 다른 요소들보다 젤 위에 표시 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 30px;
    border-radius: 15px;
}

.video-detail-title-container {
    margin-bottom: 10px;
    margin-right: 30px;
}

.video-detail-category {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-semiBold);
    margin-right: 8px;
    color: #2EB4FF;
}

.video-detail-title {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-semiBold);
}

.video-detail-media {
    margin-top: 0;
    color: #a5a5a5;
    font-size: var(--font-small);
    text-align: center;
}

.video-detail-video-container {
    text-align: center;
}

.video-detail-container img {
    width: 40vw;
    border-radius: 10px;
    margin: 0 auto;
}

.modal-title-hr {
    width:  100%;
    height: 2px;
    border: 0;
    background-color: #d9d9d9;
    margin-top: 0px;
    margin-bottom: 20px;
}

.modal-content-hr {
    width:  10vw;
    height: 4px;
    border: 0;
    background-color: #d9d9d9;
    margin-bottom: 20px;
    margin-top: 0;
}

.modal_btn_close {
    position: absolute; /* 위치를 모달의 오른쪽 상단에 고정 */
    top: 25px;
    right: 25px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.modal_btn_close:before, .modal_btn_close:after {
    content: ' '; /* 가상 요소에 공백을 추가 */
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: var(--color-black);
    top: 50%;
    left: 0;
    transform-origin: center;
}

.modal_btn_close:before {
    transform: rotate(45deg);
}

.modal_btn_close:after {
    transform: rotate(-45deg);
}
