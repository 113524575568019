.info-zone {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.user-info {
  display: flex;
  font-size: var(--font-medium);
}
.info:first-child {
  margin-right: 20px;
}
.list-table {
    width: 100%;
    table-layout: fixed;
}

.list-table td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.noticeText {
    cursor: pointer;
    display: block; /* 기본 block 형태로 유지 */
    overflow: hidden;
    white-space: nowrap; /* 한 줄에서 자르기 */
    text-overflow: ellipsis;
    line-height: 55px;
}
.noticeText:hover{
    text-decoration: underline;
}
