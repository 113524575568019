.accordion-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5vw;
    border-bottom: 1px solid #D9D9D9;
    height: 60px;
    gap: 30px;
}
.no-border .accordion-title {
    border-bottom: none;
}
.no-border .accordion-content{
    border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.accordion-title-text{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center; 
    cursor: pointer;
}
.check-container {
    position: relative;
}
.check, .checked {
    width: 1.4em;
    height: 1.4em;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); 
    cursor: pointer;
}

.check::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    width: 30%;
    height: 55%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-70%) rotateZ(40deg);
    border-right: 3px solid #D9D9D9;
    border-bottom: 3px solid #D9D9D9;
}
.checked::before{
    content: "";
    position: absolute;
    box-sizing: border-box;
    width: 30%;
    height: 55%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-70%) rotateZ(40deg);
    border-right: 3px solid var(--color-blue);
    border-bottom: 3px solid var(--color-blue);
}

.toggle-container span{
    position: relative;
}

.icon-toggle-open{
    cursor: pointer;
}
.icon-toggle-open::after{
    transform: translateX(-50%) rotate(315deg); /* 각도 */
    top: 50%;
}
.icon-toggle-close::after{
    transform:translate(-50%, -50%) rotate(135deg);
    top: 50%;

}
.accordion-content {
    height: 100px;
    padding: 2vw;
    background-color: #f9f9f9;
    overflow-y: scroll;
    margin: 0;
    white-space: pre-wrap;
}
.accordion-content::-webkit-scrollbar {
    display: none;
}

/* 노트북 & PC */
@media (min-width: 1024px) {
    .accordion-title-text p{
        font-size: var(--font-regular);
    }
    .toggle-container span::after{
        content: '';
        width: 10px; /* 사이즈 */
        height: 10px; /* 사이즈 */
        border-top: 3px solid #121212; /* 선 두께 */
        border-right: 3px solid #121212; /* 선 두께 */
        position: absolute;
        right: 0;
        transition: transform 0.3s;
        
    }
}

/* 태블릿 가로, 세로 */
@media (min-width: 768px) and (max-width: 1023px) {
    .accordion-title-text p{
        font-size: var(--font-small);
    }
    .toggle-container span::after{
        content: '';
        width: 10px; /* 사이즈 */
        height: 10px; /* 사이즈 */
        border-top: 3px solid #121212; /* 선 두께 */
        border-right: 3px solid #121212; /* 선 두께 */
        position: absolute;
        right: 0;
        transition: transform 0.3s;
    }
}

/* 모바일 가로, 세로 */
@media (max-width: 767px) {
    .accordion-title{
        height: 45px;
    }
    .accordion-content{
        padding: 3vw;
    }
    .accordion-title-text p{
        font-size: var(--font-extra-small);
    }
    .toggle-container span::after{
        content: '';
        width: 8px; /* 사이즈 */
        height: 8px; /* 사이즈 */
        border-top: 2px solid #121212; /* 선 두께 */
        border-right: 2px solid #121212; /* 선 두께 */
        position: absolute;
        right: 1.5vw;
        transition: transform 0.3s;
    }
    
}
