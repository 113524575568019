.agreement-box-content{
  border: 1px solid #D9D9D9;
  border-radius: 10px;
}
.agreement-container{
  margin-top: 10vh;
}
.agreement-all{
  display: flex;
  align-items: center;
  gap: 35px;
 
}
.info-title-text{
  
}
.checkmark-container {
  position: relative;
  cursor: pointer;
}
.checkmark {
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
  background-color: #D9D9D9;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.checkmark::before ,.checkedmark::before{
  content: "";
  position: absolute;
  box-sizing: border-box;
  width: 30%;
  height: 55%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-70%) rotateZ(40deg);
  border-right: 3px solid white;
  border-bottom: 3px solid white;
}
.checkedmark{
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
  background-color: var(--color-blue);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.agreemen-box-content{
  border: 1px solid #D9D9D9;
  border-radius: 0.5vw;
}

/* 노트북 & PC */
@media all and (width>1023px){
  .accordion-content, .agreemen-box-content p{
      font-size: var(--font-regular);
  }
}
/* 태블릿 가로, 세로 */
@media all and (min-width:768px) and (max-width:1023px){
  .accordion-content, .agreemen-box-content p{
      font-size: var(--font-small);
  }
}
/* 모바일 가로, 세로 */
@media all and (width<768px){
  .accordion-content, .agreemen-box-content p{
      font-size: 11px;
  }
}
