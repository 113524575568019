/* 기본 컨테이너 스타일 */
.category-section-container {
    width: 100%;
    position: relative;
}

/* 공통 이미지 스타일 */
.category-section-container img {
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
}

/* 컨텐츠 섹션 스타일 */
.category-section-content > div {
    margin: 0;
    padding: 0;
}

/* 헤더 스타일 */
.sticky-header {
    position: sticky;
    z-index: 500;
    background-color: rgb(54, 54, 54);
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* 모달이 열려있을 때 sticky-header 숨기기 */
body:has(.header-modal) .sticky-header {
    visibility: hidden;
}

/* 이미지 컨테이너 스타일 */
.content-image-container {
    line-height: 0;
    margin: 0;
    padding: 0;
    font-size: 0;
}

/* 프로젝트 섹션 스타일 */
.project-slide-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    overflow: hidden;
    margin: 0;
}

.project-slide-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.category-section-project {
    margin: 0;
    padding: 0;
    line-height: 0;
    font-size: 0;
    z-index: 1;
}

/* 커리큘럼 스타일 */
.curriculum-container {
    line-height: normal;
    font-size: 16px;
    text-align: center;
    margin: 0 0 7rem;
}

.curriculum-download-btn {
    background: linear-gradient(45deg, #2196F3, #21CBF3);
    color: white;
    padding: 20px 30px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 1.1rem;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(33, 150, 243, 0.3);
    cursor: pointer;
    min-width: 250px;
    border: none;
}

.curriculum-download-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(33, 150, 243, 0.4);
    background: linear-gradient(45deg, #1976D2, #00BCD4);
}

.curriculum-download-btn:active {
    transform: translateY(1px);
    box-shadow: 0 2px 10px rgba(33, 150, 243, 0.3);
}

.sticky-header{
    box-sizing: border-box;
}
/* 반응형 스타일 */
/* 기본 이미지 표시 설정 (모바일 우선) */
.webImg {
    display: none !important;
}

.mobileImg {
    display: block !important;
}

/* PC (1024px 이상) */
@media all and (min-width: 1024px) {
    .webImg {
        display: block !important;
    }
    
    .mobileImg {
        display: none !important;
    }

    .project-btn {
        width: 250px;
        height: 60px;
        line-height: 56px;
        margin: 20px auto 50px;
        font-size: 17px;
    }
    .category-section-content {
        width: 80vw;
        margin: 0 auto;
        line-height: 0;
        font-size: 0;
    }
    .project-section {
        width: 80vw;
        margin: 0 auto;
        line-height: 0;
        font-size: 0;
    }

}

/* 태블릿 (768px ~ 1023px) */
@media all and (min-width: 768px) and (max-width: 1023px) {
    .project-btn {
        width: 250px;
        height: 60px;
        line-height: 56px;
        font-size: 16px;
    }
}

/* 모바일 (767px 이하) */
@media all and (max-width: 767px) {
    .project-btn {
        width: 200px;
        height: 40px;
        line-height: 36px;
        font-size: 15px;
    }
    
    .curriculum-download-btn {
        padding: 12px 25px;
        font-size: 1rem;
    }
}

/* TOP 버튼 스타일 */
.top-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background-color: rgba(59, 59, 59, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    z-index: 9;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-button:hover {
    transform: translateY(-3px);
}

@media screen and (max-width: 768px) {
    .top-button {
        bottom: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        font-size: 12px;
    }
}

.swiper-container-wrapper {
    position: relative;
    width: 100%;
}

.custom-prev-button,
.custom-next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-prev-button {
    left: 20px;
}

.custom-next-button {
    right: 20px;
}

.custom-prev-button:hover,
.custom-next-button:hover {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-50%) scale(1.1);
}

.custom-prev-button:active,
.custom-next-button:active {
    transform: translateY(-50%) scale(0.95);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-prev-button span,
.custom-next-button span {
    font-size: 24px;
    color: #333;
    font-weight: bold;
}

/* 모바일 반응형 스타일 추가 */
@media screen and (max-width: 768px) {
    .custom-prev-button,
    .custom-next-button {
        width: 40px;
        height: 40px;
    }

    .custom-prev-button {
        left: 10px;
    }

    .custom-next-button {
        right: 10px;
    }

    .custom-prev-button span,
    .custom-next-button span {
        font-size: 20px;
    }
}
