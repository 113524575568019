.category-bar {
    position: sticky; 
    z-index: 3;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    background-color: rgb(54, 54, 54);
}

.category-bar ul {
    list-style: none;
    display: flex;
    margin: 0 auto;
    width: 80vw;
    padding: 10px 0px;

    color: white;
    box-sizing: border-box;
    gap: 5%;
    flex-wrap: wrap;
}

.category-bar li:not(.hidden-category) {
    flex-shrink: 0;
    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.hidden-category {
    display: none;
    width: 0;
    margin: 0;
    padding: 0;
    visibility: hidden;
}

/* 노트북 & PC */
@media all and (width>1023px){  
    .category-bar li {
        font-size: var(--font-medium);
        cursor: pointer;
    }
    .category-bar {
        top: 90px;
    }

}
/* 태블릿 가로, 세로 */
@media all and (min-width:768px) and (max-width:1023px){
    .category-bar li {
        font-size: var(--font-medium);
        cursor: pointer;
    }
    .category-bar {
        top: 50px;
    }
}

/* 모바일 가로, 세로 */
@media all and (width<768px){
    .category-bar li {
        font-size: var(--font-small);
        cursor: pointer;
    }
    .category-bar {
        top: 50px;
    }
}

/* 활성 카테고리 스타일 추가 */
.category-bar li.active {
    color: var(--color-primary); /* 또는 원하는 강조 색상 */
    font-weight: bold;
    position: relative;
}

.category-bar li.active::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-primary); /* 또는 원하는 강조 색상 */
}
