input[type="date"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.info-content input {
    padding-left: 1%;
}

.placeholder {
    background-color: white;
    position: absolute;
    width: 100%;
    height: 30px;
    margin: 15px 0;
    padding: 0px 5%;
    line-height: 30px;
    box-sizing: border-box;
    border: 1px solid var(--color-lightLineGray);
    font-size: var(--font-extra-small);
}

.date-input:focus + .placeholder,
.date-input:not(:placeholder-shown) + .placeholder {
    display: none;
}

.info-content input[type="radio"] {
    padding: 0;
    margin: 0;
    width: 10px;
    height: 10px;
}
.input-text input{
    background-color: #F0F0F0 !important;
}
.input-email-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-email{
    margin-left: 5px !important;
}
.input-content-wrap{
    width: 100%;
}
/* 노트북 & PC */
@media (min-width: 1024px) {
    .info-content {
        border-bottom: 1px solid var(--color-black);
        display: flex;
    }
    .info-content label:nth-child(1) {
        width: 40%;
    }
    .input-content {
        width: 60%;
    }
    .info-content label:nth-child(1) {
        background-color: #F9F9F9;
        margin: 0;
        line-height: 75px;
        padding-left: 2vw;
        box-sizing: border-box;
        font-size: var(--font-regular);
    }
    .info-content input,
    .info-content select {
        width: 80%;
        margin: 20px auto 0px;
        height: 35px;
        box-sizing: border-box;
        border: 1px solid #D9D9D9;
        font-size: var(--font-small);
        display: block;
    }
    .graduation-date {
        margin: 20px 10% 5px;
        font-size: var(--font-small);
        text-align: left;
    }
    .input-text {
        display: inline-block;
        width: 100%;
        margin: 0px auto;
        line-height: 60px;
        font-size: var(--font-extra-small);
        box-sizing: border-box;
    }
    .input-radio-content {
        display: flex;
        align-items: center;
        width: 80%;
        line-height: 35px;
        margin: 20px auto 0px;
    }
    .at-sign {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        margin: 15px 5px 0px;
    }  
    .address-button {
        width: 90px;
        height: 35px;
        margin: 15px 10% 0px;
        border-radius: 5px;
        font-size: var(--font-small);
    }
    .label-radio {
        background-color: white;
        display: inline-block;
        margin-right: 10%;
        padding-left: 15px;
        box-sizing: border-box;
        font-weight: var(--font-weight-medium);
        font-size: var(--font-regular);
    }
    .input-email-content{
       width: 80%;
       margin: 0 auto;
    }
    .textarea-input {
        width: 80%;
        height: 120px;
        margin: 20px auto 0px;
        display: block;
        resize: none;
        box-sizing: border-box;
        border: 1px solid #D9D9D9;
    }
    .errorMsg{
        color: #F62525;
        font-size: var(--font-small);
        text-align: left; 
        width: 80%;
        height: 20px;
        line-height: 20px;
        margin: 0 auto;
    }
    .error-Msg{
        color: #F62525;
        font-size: var(--font-extra-small);
        width: 100%;
        height: 20px;
        line-height: 20px;
        margin: 0;
    }
    .graduation-date-input{
        margin-top: 0 !important;
    }
    .input-email,.select-email{
        width: 30% !important;
        margin: 20px 0px 0px !important;
    }
}

/* 태블릿 가로, 세로 */
@media (min-width: 768px) and (max-width: 1023px) {
    .info-content {
        border-bottom: 1px solid var(--color-black);
        display: flex;
    }
    .info-content label:nth-child(1) {
        width: 40%;
    }
    .input-content {
        width: 60%;
    }
    .info-content label:nth-child(1) {
        background-color: #F9F9F9;
        margin: 0;
        line-height: 75px;
        padding-left: 2vw;
        box-sizing: border-box;
        font-size: var(--font-small);
    }
    .info-content input,
    .info-content select {
        width: 80%;
        margin: 20px auto 0px;
        height: 35px;
        box-sizing: border-box;
        border: 1px solid #D9D9D9;
        font-size: var(--font-extra-small);
        display: block;
    }
    .graduation-date {
        margin: 20px 10% 5px;
        font-size: var(--font-extra-small);
        text-align: left;
    }
    .input-text {
        display: inline-block;
        width: 100%;
        margin: 0px auto;
        line-height: 60px;
        font-size: var(--font-extra-small);
        box-sizing: border-box;
    }
    .input-radio-content {
        display: flex;
        align-items: center;
        width: 80%;
        line-height: 35px;
        margin: 20px auto 0px;
    }
    .at-sign {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        margin: 15px 5px 0px;
    }  
    .address-button {
        width: 90px;
        height: 30px;
        margin: 15px 10% 0px;
        border-radius: 5px;
        font-size: var(--font-extra-small);
    }
    .label-radio {
        background-color: white;
        display: inline-block;
        margin-right: 10%;
        padding-left: 15px;
        box-sizing: border-box;
        font-weight: var(--font-weight-medium);
        font-size: var(--font-small);
    }
    .input-email-content{
       width: 80%;
       margin: 0 auto;
    }
    .textarea-input {
        width: 80%;
        height: 120px;
        margin: 20px auto 0px;
        display: block;
        resize: none;
        box-sizing: border-box;
        border: 1px solid #D9D9D9;
    }
    .errorMsg{
        color: #F62525;
        font-size: var(--font-extra-small);
        width: 80%;
        height: 20px;
        line-height: 20px;
        text-align: left;
        margin: 0 auto;

    }
    .error-Msg{
        color: #F62525;
        font-size: var(--font-extra-small);
        width: 100%;
        height: 20px;
        line-height: 20px;
        margin: 0;
    }
    .graduation-date-input{
        margin-top: 0 !important;
    }
    .input-email,.select-email{
        width: 30% !important;
        margin: 20px 0px 0px !important;
    }
}

/* 모바일 가로, 세로 */
@media all and (width<768px) {
    .info-content label:nth-child(1) {
        width: 100%;
    }
    .input-content {
        width: 100%;
    }
    .info-content label:nth-child(1) {
        margin: 0;
        line-height: 40px;
        box-sizing: border-box;
        font-size: var(--font-extra-small);
    }
    .info-content input,
    .info-content select {
        width: 100%;
        height: 30px;
        box-sizing: border-box;
        border: 1px solid #D9D9D9;
        font-size: var(--font-extra-small);
        display: block;

    }
    .graduation-date {
        font-size: var(--font-extra-small);
    }
    .input-text {
        margin: 0px auto;
        line-height: 30px;
        font-size: var(--font-extra-small);
        box-sizing: border-box;
    }
    .input-radio-content {
        display: flex;
        align-items: center;
        line-height: 30px;
        margin: 0px auto 0px;
    }
    
    .at-sign {
        height: 30px;
        display: inline-block;
        margin: 0 5px;
    }  
    .address-button {
        width: 90px;
        height: 30px;
        margin: 0px;
        border-radius: 5px;
        font-size: var(--font-extra-small);
    }
    .label-radio {
        background-color: white;
        display: inline-block;
        margin-right: 10%;
        padding-left: 15px;
        box-sizing: border-box;
        font-weight: var(--font-weight-medium);
        font-size: var(--font-extra-small);
    }
    .textarea-input {
        width: 100%;
        height: 120px;
        margin: 0px auto;
        display: block;
        resize: none;
        box-sizing: border-box;
        border: 1px solid #D9D9D9;
    }
    .errorMsg{
        display: inline-block;
        color: #F62525;
        font-size: var(--font-extra-small);
        margin: 0px 0px 10px;
    }
    .error-Msg{
        color: #F62525;
        font-size: var(--font-extra-small);
        width: 100%;
        height: 20px;
        line-height: 20px;
        margin: 0;
    }
    .input-address{
        margin-top: 20px;
    }
    .input-graduation{
        margin-bottom: 20px;
    }
    .input-email,.select-email{
        width: 30% !important;
    }

}

.date-picker-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #D9D9D9;
    cursor: pointer;
}

/* react-datepicker 커스텀 스타일 */
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

/* 졸업년월 DatePicker 스타일 */
.graduation-date + .react-datepicker-wrapper .date-picker-input {
    margin-top: 8px;
}
