.bannerWebImage, .bannerMobileImage{
    width: 100%;
    height: 250px;
    margin-top: 90px;
    object-fit:cover;

}

/* 노트북 & PC */
@media all and (width>1023px){  
    .bannerMobileImage{
        display: none;
    }
    .bannerWebImage, .bannerMobileImage{
        margin-top: 90px;
    }
}
/* 태블릿 가로, 세로 */
@media all and (min-width:768px) and (max-width:1023px){
    .bannerMobileImage{
        display: none;
    }
    .bannerWebImage, .bannerMobileImage{
        margin-top: 50px;
    }
}
  
/* 모바일 가로, 세로 */
@media all and (width<768px){
    .bannerWebImage{
        display: none;
    }
    .bannerWebImage, .bannerMobileImage{
        margin-top: 50px;
    }
}
  