.main-image-zone {
  position: fixed; /* 화면 상단에 고정 */
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
}
.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.scroll-text {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: var(--font-small);
  font-weight: var(--font-weight-regular);
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  z-index: 1;
  animation: motion 0.5s linear 0s infinite alternate;

  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
@keyframes motion {
	0% {margin-top: 0px;}
	100% {margin-top: 10px;}
} 