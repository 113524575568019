.swiper-container{
  position: relative;
}
.slide-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container2 {
  justify-content: center;
  display: flex;
}
.edu-explain-zone {
  width: 450px;
  line-height: 160%;
  font-size: var(--font-regular);
  font-weight: var(--font-weight-regular);
  margin: 10px 0;
  color: #fff;
}
.apply-zone {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.roadmap-zone {
  margin-top: 50px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.roadmap-zone button {
  padding: 20px 0;
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-large);
}
.accordion-content {
  margin-top: 10px;
}
.roadmap-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.custom-swiper-prev,
.custom-swiper-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #333;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8); 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prev-icon, .next-icon{
  width: 30%;
  height: 46%;
}
.custom-swiper-prev {
  left: -10px; /* 왼쪽 끝에 배치 */
}

.custom-swiper-next {
  right: -10px; /* 오른쪽 끝에 배치 */
}

.custom-swiper-prev:active,
.custom-swiper-next:active {
  background-color: rgba(107, 100, 122, 0.8);
  color: #fff;
}

.zone-container{
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 20px 20px;
}
.applyBtn-container{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.apply-cnt{
  display: flex;
  flex-direction: row;
  align-items: center ;
}
.edu-content-box {
  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}


/* 노트북 & PC */
@media all and (width>1023px) {
  .swiper-zone {
    width: 1000px;
  }
  .container1 {
    display: flex;
  }
  .edu-img-zone {
    min-width: 450px;
    width: 450px;
    height: 450px;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    justify-content: center;
  }
  .edu-content-box {
    display: flex;
    background:none;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    flex-direction: column;
  }
  .edu-info-header {
    font-size: var(--font-extra-large);
    font-weight: var(--font-weight-semiBold);
    color: #fff;
  }
  .tag-list {
    display: flex;
    flex-direction: row;
    padding: 0;
    font-size: var(--font-regular);
    color: #6ECEF4;
    margin: 0;
  }
  .tag-list > li {
    margin-right: 10px;
    padding: 8px;
    list-style-type: none;
  }
  .info-zone {
    padding-left: 30px;
    text-align: left;
    flex-direction: column;
  }
  .edu-imp-text, .edu-date-text {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-medium);
    color: #fff;
    margin-left: 10px;
  }
  .roadmap-close{
    margin-left: auto;
    cursor: pointer;
  }

  /*추가*/
  .edu-imp-text, .edu-imp-text-header{
    color: #FEF799;
  }
  .edu-date-text-header, .edu-imp-text-header{
    font-weight: var(--font-weight-semiBold);
  }

  .edu-date-text-header{
    color: #fff;
  }
  

  .edu-explain-zone {
    font-size: var(--font-regular);
    word-break: break-all;
    font-weight: var(--font-weight-regular);
    margin: 10px 0 20px 0;
    color: #fff;
  }
  .apply-header {
    font-size: var(--font-regular);
    font-weight: var(--font-weight-semiBold);
    color: #FFF;
  }
  .apply-text{
    color: #FFF;
    font-size: var(--font-medium);
    margin-left: 10px;
  }
  .applyBtn {
    width: 200px;
    height: 60px;
    border: 1px solid #fff;
    font-size: var(--font-large);
    color: #fff;
    background: none;
    border-radius: 10px;
    cursor: pointer;
  }
  .edu-date{
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 15px 0;
  }
  .roadmap-btn-zone{
    margin: 15px 0;
    display: flex;
    justify-content: flex-start;
  }
  .roadmapBtn{
    display: flex;
    padding: 5px 10px;
    border: none;
    border-radius: 20px;
    font-size: var(--font-regular);
    background-color: #FEF799;
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    cursor: pointer;
  }
  .roadmap-icon{
    width: 24px;
    margin-left: 10px;
  }
}
/* 태블릿 가로, 세로 */
@media all and (min-width: 768px) and (max-width: 1023px) {
  .swiper-zone {
    width: 540px;
  }
  .container1 {
    display: flex;
    flex-direction: column;
  }
  .edu-img-zone {
    width: 450px;
    height: 450px;
    overflow: hidden;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .edu-content-box {
    display: flex;
    background:none;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    flex-direction: column;
  }
  .edu-info-header {
    font-size: var(--font-extra-large);
    font-weight: var(--font-weight-semiBold);
    color: #fff;
  }
  .tag-list {
    display: flex;
    flex-direction: row;
    padding: 0;
    font-size: var(--font-regular);
    color: #6ECEF4;
    margin: 0;
  }
  .tag-list > li {
    margin-right: 10px;
    padding: 8px;
    list-style-type: none;
  }
  .info-zone {
    padding: 10px 45px;
    text-align: left;
    flex-direction: column;
  }
  .edu-imp-text, .edu-date-text {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-medium); 
    margin: 10px 0 10px 10px;
    color: #fff;
  }
  .edu-imp-text, .emp-imp-text-header{
    color: #FEF799;
  }
  .edu-explain-zone {
    font-size: var(--font-regular);
    word-break: break-all;
    font-weight: var(--font-weight-regular);
    margin: 10px 0 20px 0;
    color: #fff;
  }
  .apply-header {
    font-size: var(--font-regular);
    font-weight: var(--font-weight-semiBold);
    color: #fff;
  }
  .apply-text{
    color: #FFF;
    font-size: var(--font-regular);
    margin-left: 10px;
  }
  .applyBtn {
    width: 200px;
    height: 60px;
    border: 1px solid #fff;
    font-size: var(--font-large);
    color: #fff;
    background:none;
    border-radius: 10px;
    cursor: pointer;
  }
  .edu-date{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .edu-imp-text, .edu-imp-text-header{
    color: #FEF799;
  }
  .edu-date-text-header, .edu-imp-text-header{
    font-weight: var(--font-weight-semiBold);
  }
  .edu-date-text-header{
    color: #fff;
  }
  .roadmap-close{
    margin-left: auto;
    cursor: pointer;
    width: 80%;
  }
  .roadmap-btn-zone{
    margin: 15px 0;
    display: flex;
    justify-content: flex-end;
  }
  .roadmapBtn{
    display: flex;
    padding: 10px 25px;
    border: none;
    border-radius: 20px;
    font-size: var(--font-regular);
    background-color: #FEF799;
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    cursor: pointer;
  }
  .roadmap-icon{
    width: 24px;
    margin-left: 10px;
  }
}
/* 모바일 가로, 세로 */
@media all and (width<768px) {
  .swiper-zone {
    width: 320px;
  }
  .container1 {
    display: flex;
    flex-direction: column;
  }
  .edu-img-zone {
    width: 300px;
    height: 300px;
    overflow: hidden;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .edu-content-box {
    display: flex;
    background:none;
    border-radius: 10px;
    padding: 20px;
    width: 88%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    flex-direction: column;
  }
  .edu-info-header {
    font-size: var(--font-regular);
    font-weight: var(--font-weight-semiBold);
    color: #fff;
  }
  .tag-list {
    display: flex;
    flex-direction: row;
    padding: 0;
    font-size: var(--font-extra-small);
    color: #6ECEF4;
    margin: 0;
  }
  .tag-list > li {
    margin-right: 10px;
    padding: 8px;
    list-style-type: none;
  }
  .info-zone {
    text-align: left;
    flex-direction: column;
    padding: 10px 0;
  }
  .edu-imp-text, .edu-date-text {
    font-size: var(--font-small);
    font-weight: var(--font-weight-medium);
    margin: 10px 0 10px 10px;
    color: #fff;
  }
  .edu-imp-text{
    color: #FEF799;
  }
  .edu-explain-zone {
    font-size: var(--font-small);
    word-break: break-all;
    width: 240px;
    font-weight: var(--font-weight-regular);
    margin: 10px 0 20px 0;
    color: #fff;
  }
  .apply-header {
    font-size: var(--font-small);
    font-weight: var(--font-weight-semiBold);
    color: #fff;
  }

  .apply-text{
    color: #FFF;
    font-size: var(--font-small);
    margin-left: 10px;
  }
  .applyBtn {
    width: 150px;
    height: 48px;
    border: 1px solid #fff;
    font-size: var(--font-small);
    color: #fff;
    background: none;
    border-radius: 10px;
    cursor: pointer;
  }
  .edu-date{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .edu-imp-text, .edu-imp-text-header{
    color: #FEF799;
  }
  .edu-date-text-header, .edu-imp-text-header{
    font-weight: var(--font-weight-semiBold);
    font-size: var(--font-small);
  }
  .edu-date-text-header{
    color: #fff;
  }

  .roadmap-close{
    margin-left: auto;
    cursor: pointer;
    width: 80%;
  }
  .roadmap-btn-zone{
    margin: 15px 0;
    display: flex;
    justify-content: center;
  }
  .roadmapBtn{
    display: flex;
    padding: 8px 15px;
    border: none;
    border-radius: 20px;
    font-size: var(--font-small);
    background-color: #FEF799;
    font-weight: var(--font-weight-semiBold);
    align-items: center;
    cursor: pointer;
  }
  .roadmap-icon{
    width: 16px;
    margin-left: 10px;
  }
}
