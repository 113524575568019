/* 기본 컨테이너 스타일 */
.course-detail-container {
    background-color: black;
    height: 100%;
}

/* 컨텐츠 공통 스타일 */
.detail-content {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10vh;
}

/* 반응형 스타일 */
/* 노트북 & PC (1024px 이상) */
@media all and (min-width: 1024px) {  
    .detail-content {
        margin: 90px auto 0;
    }
}

/* 태블릿 & 모바일 (1023px 이하) */
@media all and (max-width: 1023px) {
    .detail-content {
        margin: 50px 0 0;
    }
}