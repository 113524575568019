.detailmenu-container {
    /* border: 2px solid blue; */
    width: 80vw;
    margin: 0 auto;
}

.detailmenu-list-container {
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3vw;
    flex-wrap: wrap; /* 항목이 화면에 넘치면 줄바꿈하기 */
    margin-top: 60px;
    margin-bottom: 60px;
}

.detailmenu-list-container span {
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.detailmenu-container span {
    font-weight: var(--font-weight-medium);
    font-size: 22px;
    position: relative;
    cursor: pointer;
    flex-wrap: wrap; /* 줄바꿈 허용 */
    white-space: nowrap; /* 세로로 글자가 쓰이지 않도록 설정 */
}

.detailmenu-container span::after {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    background-color: var(--color-black);
    bottom: -5px;
    left: 50%; /* 중앙에서 시작 */
    transition: width 0.3s ease, left 0.3s ease; /* 밑줄 애니메이션 */
}

.detailmenu-container span.active::after {
    width: 100%; /* 클릭된 탭의 밑줄이 전체 길이로 확장됨 */
    left: 0; /* 양쪽으로 퍼져나가도록 조정 */
}

.detailmenu-container span.active {
    color: var(--color-black); /* 클릭된 탭의 폰트 색상을 검정색으로 변경 */
}


/* 첫 퍼블리싱을 웹을 기준으로 했지만 width>1023을 적용시키려면(1023.01 부터의 소수점을 적용시키려면) 바뀐 부분은 웹 전용으로 한번 더 써줘야함 */

@media all and (width>1023px) {

    .detailmenu-list-container {
        gap: 3vw;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .detailmenu-container span {
        font-size: 22px;
    }
}

@media all and (min-width:768px) and (max-width:1023px) {

    .detailmenu-list-container {
        gap: 4vw;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    .detailmenu-container span {
        font-size: 18px;
    }
}

@media all and (width<768px) {

    .detailmenu-list-container {
        gap: 5vw;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .detailmenu-container span {
        font-size: 14px;
    }
}