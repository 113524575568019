
.content-box {
  width: 100%;
}

.app-list {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.visitor-chart {
  width: 86%;
  height: 40vh;
  background-color: #fff;
  box-shadow: 5px 5px 20px #cccecf;
  border-radius: 10px;
  padding: 20px 2% 20px 2%;
}

.comul-app,
.today-app {
  display: inline-block;
  width: 56%;
  height: 35vh;
  background-color: #fff;
  box-shadow: 5px 5px 20px #cccecf;
  border-radius: 10px;
  padding: 20px 2% 20px 2%;
  margin-top: 4vh;
}

.today-app {
  margin-left: 4%;
  width: 40%;
}
.pageName-dashboard {
  font-size: 44px;
  font-weight: var(--font-weight-semiBold);
  margin-bottom: 20px;
  font-family: 'Pretendard';
}
.dash-visitor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dash-title {
    margin-left: 10px;
    font-size: var(--font-large);
    font-weight: var(--font-weight-semiBold);
  }
}
.dash-header {
  display: flex;
  align-items: center;
  .dash-title {
    margin-left: 10px;
    font-size: var(--font-large);
    font-weight: var(--font-weight-semiBold);
  }
}
.chart-zone {
  margin-top: 30px;
  height: 32vh;
  width: 100%;
}
.dash-visitor {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.calendar-zone {
  display: flex;
  align-items: center;
}
.calendar-zone > label {
  margin-right: 10px;
}

.datePicker {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-lightLineGray);
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  text-align: center;
  padding-right: 14px;
  font-family: "Pretendard";
  font-size: var(--font-regular);
}
.calendar-icon {
  height: 28px !important;
}
.category-selectbox {
  font-size: var(--font-regular);
  font-weight: var(--font-weight-medium);
  padding: 10px;
  border: none;
  cursor: pointer;
}
.prop-cnt {
  margin-top: 20px;
  height: 28vh;
  overflow-y: scroll;
}
.prop-table {
  width: 100%;
  border-collapse: collapse;
  font-size: var(--font-medium);
  color: var(--color-black);
}
.prop-table th,
.prop-table td {
  height: 30px;
  padding: 10px;
  border-bottom: 1px solid #e8e7e7;
  font-size: var(--font-regular);
}
.prop-table th {
  background-color: #f6f8f9;
  font-weight: var(--font-weight-semiBold);
  position: sticky;
  top: 0;
}
.prop-table tbody {
  text-align: center;
}
.prop-table tbody td {
  cursor: pointer;
}
.pie-graph{
    width: 100%;
    height: 28vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dash-table{
width: 85px;
min-width: 85px;
}
.dash-date{
  width: 100px;
  min-width: 100px;
}
.dash-class{
  min-width: 100px;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}