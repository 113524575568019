.category-search-container {
    width: 80vw; /* 필요에 맞게 조정 */
    min-height: 44px;
    margin: 0 auto; /* 가운데 정렬 */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* 첫 퍼블리싱을 웹을 기준으로 했지만 width>1023을 적용시키려면(소수점을 적용시키려면) 바뀐 부분은 웹 전용으로 한번 더 써줘야함 */

@media all and (width>1023px) {

    .v-contents-title-container {
        min-height: 54px;
    }

    .story-contents-wrapper {
        margin-bottom: 120px;
    }

    .contents-list {
        padding: 20px;
    }

    .contents-list, .contents-text-container, .contents-list img {
        height: 200px;
    }

    .contents-list img {
        width: 300px;
    }

    .contents-category {
        font-size: var(--font-large);
    }


    .contents-title-container {
        min-height: 60px;
        line-height: 28px;
    }


    .contents-title {
        font-size: var(--font-large);
    }

    .contents-introduce {
        height: 80px;
        margin-top: 22px;
        margin-bottom: 22px;
        font-size: var(--font-medium);
    }

    .contents-introduce p {
        margin-top: 0;
    }

    .contents-regi-date {
        font-size: var(--font-regular);
    }


    .v-contents-category {
        font-size: var(--font-large);
    }

    .v-contents-title {
        font-size: var(--font-large);
    }

    .v-contents-introduce {
        margin-top: 10px;
        margin-bottom: 15px;
        height: 45px;
        font-size: var(--font-regular);
    }

    .v-contents-introduce p {
        margin-top: 0;
    }

    .v-contents-regi-date {
        font-size: var(--font-small);
    }


    .see-more-btn-container {
        margin-top: 40px;
    }
    
    .see-more-btn {
        padding: 12px;
        font-size: var(--font-regular);
    }


    .video-detail-modal-container {
        padding: 30px;
    }

    .modal-content-hr, .modal-title-hr {
        margin-bottom: 20px;
    }

    .modal_btn_close:before, .modal_btn_close:after {
        width: 30px;
    }

    .modal_btn_close {
        top: 22px;
        right: 22px;
        width: 30px;
        height: 30px;
    }

    .video-detail-title-container {
        margin-right: 30px;
    }

    .video-detail-modal-container {
        padding: 30px;
    }

    .video-detail-category {
        font-size: var(--font-medium);
    }
    
    .video-detail-title {
        font-size: var(--font-medium);
    }
    
    .video-detail-media {
        font-size: var(--font-small);
    }


    .no-contents-message {
        font-size: var(--font-regular);
        padding: 10vw;
    }


    .contents-container-hr {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .contents-list-hr {
        margin-top: 20px;
        margin-bottom: 20px;
    }


    .v-contents-list-container {
        grid-template-columns: repeat(3, 1fr); /* 한 줄에 3개씩 */
    }

    .v-contents-list img {
        height: 230px;
    }
}

@media all and (min-width:768px) and (max-width:1023px) {

    .v-contents-title-container {
        min-height: 44px;
    }

    .story-contents-wrapper {
        margin-bottom: 80px;
    }

    .contents-list {
        padding: 15px;
    }

    .contents-list, .contents-text-container, .contents-list img {
        height: 150px;
    }

    .contents-list img {
        width: 226px;
    }

    .contents-category {
        font-size: var(--font-regular);
    }


    .contents-title-container {
        min-height: 50px;
        line-height: 24px;
    }


    .contents-title {
        font-size: var(--font-regular);
    }

    .contents-introduce {
        height: 60px;
        margin-top: 15px;
        margin-bottom: 14px;
        font-size: var(--font-small);
    }

    .contents-introduce p {
        margin-top: 0;
    }

    .contents-regi-date {
        font-size: var(--font-extra-small);
    }


    .v-contents-category {
        font-size: var(--font-regular);
    }

    .v-contents-title {
        font-size: var(--font-regular);
    }

    .v-contents-introduce {
        height: 36px;
        margin-top: 7px;
        margin-bottom: 10px;
        font-size: var(--font-extra-small);
    }

    .v-contents-introduce p {
        margin-top: 0;
    }

    .v-contents-regi-date {
        font-size: var(--font-super-extra-small);
    }


    .see-more-btn-container {
        margin-top: 30px;
    }
    
    .see-more-btn {
        padding: 11px;
        font-size: var(--font-small);
    }


    .video-detail-modal-container {
        padding: 20px;
    }

    .modal-content-hr, .modal-title-hr {
        margin-bottom: 15px;
    }

    .modal_btn_close:before, .modal_btn_close:after {
        width: 23px;
    }

    .modal_btn_close {
        top: 15px;
        right: 15px;
        width: 23px;
        height: 23px;
    }

    .video-detail-title-container {
        margin-right: 25px;
    }

    .video-detail-modal-container {
        padding: 20px;
    }

    .video-detail-category {
        font-size: var(--font-regular);
    }
    
    .video-detail-title {
        font-size: var(--font-regular);
    }
    
    .video-detail-media {
        font-size: var(--font-extra-small);
    }


    .no-contents-message {
        font-size: var(--font-small);
        padding: 10vw;
    }


    .contents-container-hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .contents-list-hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .v-contents-list-container {
        grid-template-columns: repeat(2, 1fr); /* 한 줄에 2개씩 */
    }

    .v-contents-list img {
        height: 190px;
    }
}

@media all and (width<768px) {

    .v-contents-title-container {
        min-height: 38px;
    }

    .story-contents-wrapper {
        margin-bottom: 50px;
    }

    .contents-list {
        padding: 10px;
    }

    .contents-list, .contents-text-container, .contents-list img {
        height: 100px;
    }

    .contents-list img {
        width: 126px;
    }

    .contents-category {
        font-size: var(--font-extra-small);
    }

    .contents-title {
        font-size: var(--font-extra-small);
    }


    .contents-title-container {
        
    }


    .contents-introduce {
        height: 47px;
        margin-top: 6px;
        margin-bottom: 6px;
        font-size: var(--font-super-extra-small);
    }

    .contents-introduce p {
        margin-top: 0;
    }

    .contents-regi-date {
        font-size: var(--font-ultra-super-extra-small);
    }


    .v-contents-category {
        font-size: var(--font-extra-small);
    }

    .v-contents-title {
        font-size: var(--font-extra-small);
    }

    .v-contents-introduce {
        height: 30px;
        margin-top: 5px;
        margin-bottom: 7px;
        font-size: var(--font-super-extra-small);
    }

    .v-contents-introduce p {
        margin-top: 0;
    }

    .v-contents-regi-date {
        font-size: var(--font-ultra-super-extra-small);
    }


    .see-more-btn-container {
        margin-top: 20px;
    }
    

    .see-more-btn {
        padding: 9px;
        font-size: var(--font-extra-small);
    }



    .modal-content-hr, .modal-title-hr {
        margin-bottom: 10px;
    }

    .modal_btn_close:before, .modal_btn_close:after {
        width: 20px;
    }

    .modal_btn_close {
        top: 12px;
        right: 12px;
        width: 20px;
        height: 20px;
    }

    .video-detail-title-container {
        margin-right: 22px;
    }

    .video-detail-modal-container {
        padding: 15px;
    }

    .video-detail-category {
        font-size: var(--font-small);
    }
    
    .video-detail-title {
        font-size: var(--font-small);
    }
    
    .video-detail-media {
        font-size: var(--font-super-extra-small);
    }


    .no-contents-message {
        font-size: var(--font-extra-small);
        padding: 10vw;
    }


    .contents-container-hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .contents-list-hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    
    .v-contents-list-container {
        grid-template-columns: repeat(1, 1fr); /* 한 줄에 2개씩 */
    }

    .v-contents-list img {
        height: 200px;
    }
}