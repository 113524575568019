p, span {
    color: var(--color-black);
}

.search-container {
    /* width: 80vw; */
    margin: 0 auto;
    margin-right: 0;
}

.search-set-container {
    /* width: 80vw; */
    display: flex;
    align-items: center;
    justify-content: end;
}

.search-container input {
    width: 30vw;
    font-size: var(--font-regular);
    border: 1px solid #d9d9d9;
    border-right-color: transparent;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 9px;
    box-sizing: border-box;

    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.search-container span {
    min-width: 44px;
    font-weight: var(--font-weight-semiBold);
    font-size: var(--font-regular);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: cornflowerblue;
    color: white;
    box-sizing: border-box;
    cursor: pointer;

    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}


.search-icon {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.lens {
    position: absolute;
    top: 5px;
    left: 4px;
    width: 19px;
    height: 19px;
    border: 2px solid var(--color-black);
    border-radius: 50%;
}

.handle {
    position: absolute;
    top: 24px;
    left: 25px;
    width: 2px;
    height: 11px;
    background-color: var(--color-black);
    border-radius: 5px;
    transform: rotate(135deg);
}



/* 첫 퍼블리싱을 웹을 기준으로 했지만 width>1023을 적용시키려면(소수점을 적용시키려면) 바뀐 부분은 웹 전용으로 한번 더 써줘야함 */

@media all and (width>1023px) {

    .search-container input {
        font-size: var(--font-regular);
        padding: 12px;
    }
    
    .search-container span {
        font-size: var(--font-regular);
        padding: 12px;
    }


    .search-icon {
        width: 35px;
        height: 35px;
    }

    .lens {
        width: 19px;
        height: 19px;
    }
    
    .handle {
        top: 24px;
        left: 25px;
        height: 11px;
    }

}

@media all and (min-width:768px) and (max-width:1023px) {
  
    .search-container input {
        font-size: var(--font-small);
        padding: 11px;
    }
    
    .search-container span {
        font-size: var(--font-small);
        padding: 11px;
    }


    .search-icon {
        width: 32px;
        height: 32px;
    }

    .lens {
        width: 14px;
        height: 14px;
    }
    
    .handle {
        top: 20px;
        left: 20px;
        height: 9px;
    }
}

@media all and (width<768px) {

    .search-container input {
        font-size: var(--font-extra-small);
        padding: 10px;
    }
    
    .search-container span {
        font-size: var(--font-extra-small);
        padding: 10px;
    }


    .search-icon {
        width: 30px;
        height: 30px;
    }

    .lens {
        width: 10px;
        height: 10px;
    }
    
    .handle {
        top: 17px;
        left: 17px;
        height: 7px;
    }
}