.app {
  display: flex;
  margin: 0;
  height: 100%;
  min-height: 100vh;
  background-color: #f6f8f9;
}
.login-zone{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 99vw;
}

.login-area{
  width: 400px;
  height: 240px;
  background-color: #fff;
  box-shadow: 5px 5px 20px #cccecf;
  border-radius: 10px;
  padding: 80px;
  padding-top: 130px;
}
.login-title{
  font-size: var(--font-super-extra-large);
  font-weight: var(--font-weight-bold);
  margin-bottom: 30px;
}
.input-zone{
  margin-bottom: 20px;
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-input{
  width: 300px;
  height: 22px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--color-lightLineGray);
}
.loginBtn{
  width: 400px;
  height: 50px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: var(--font-medium);
  font-weight: var(--font-weight-semiBold);
}